import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { Bookmark } from 'bdx-af-ui/icons'
import { colours } from 'bdx-af-ui/theme'

import { setLeadImageOnAsset, getAssetByUuid } from '../../../store/ducks/assets/index'
import { IRootState } from '../../../store/rootReducer'

interface IImageProps {
  src: string
  size: string
  fileId: string
  assetUuid: string
  isLead: boolean
  isImageType: boolean
  isExpanded: boolean
  isEditable: boolean
  setLeadImageOnAsset: typeof setLeadImageOnAsset
  onLoad?: () => void
}

interface IBookmarkIconContainerProps {
  isEditable: boolean
}

const Container = styled.div`
  position: relative;
`

const ImageContainer = styled.img`
  object-fit: contain;
  padding: 0.2rem;
`

const BookmarkIconContainer = styled.div<IBookmarkIconContainerProps>`
  position: absolute;
  top: 0;
  right: 0.7rem;
  cursor: ${({ isEditable }) => isEditable ? 'pointer' : 'not-allowed'};
`

export const Image: FunctionComponent<IImageProps> = ({ onLoad, src, size, isLead, isImageType, fileId, assetUuid, setLeadImageOnAsset, isEditable, isExpanded }) => {
  const bookmarkColor = (isLead ? colours.primary.main : colours.text.disabled)
  const bookmarkOutline = (isLead ? colours.text.disabled : colours.primary.main)
  const titleText = isEditable ? 'Set as lead image' : 'Unable to change lead image while sale in progress'
  return (
    <Container>
      <ImageContainer
        src={src}
        onLoad={onLoad}
        width={size}
        height={size}
      />
      {
        isImageType && isExpanded &&
        <BookmarkIconContainer
          isEditable={isEditable}
          data-testid='lead-image-icon'
          onClick={() => {
            if (isEditable) setLeadImageOnAsset({ fileId, assetUuid })
          }}
          title={titleText}
        >
          <Bookmark
            fillColor={bookmarkColor}
            strokeColor={bookmarkOutline}
          />
        </BookmarkIconContainer>
      }
    </Container>
  )
}

const mapStateToProps = (state: IRootState, props: Partial<IImageProps>) => ({
  isEditable: getAssetByUuid(props.assetUuid)(state).assetStatus !== 'SALES_IN_PROGRESS'
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  setLeadImageOnAsset
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Image)
