import request from 'superagent'

const params = new URLSearchParams(document.location.search.substring(1))

export interface IAssetResponse {
  id: number
  uuid: string
  title: string
  summary?: any
  description?: any
  refNo?: any
  category?: any
  quantity?: any
  language?: string
  createdDate?: number
  updatedDate?: number
  received?: any
  assetStatus?: string
  archived?: boolean
  approvalStatus?: string
  user?: object
  assetType?: string
  buyersPremium?: any
  biddingReserve?: null
  buyersPremiumTax?: any
  bidIncrement?: any
  hammerTax?: any
  charges?: any
  chargesTax?: any
  openingBid?: any
  displayPrice?: number
  unitOfMeasure?: any
  biddingType?: any
  allowContactSeller?: boolean
  currency?: string
  purchaseOption?: string
  minimumAcceptableOffer?: number
  numberOfUnits?: any
  pointOfReference?: any
  auctionTitle?: any
  saleType?: any
  endDateAndTime?: any
  startDateAndTime?: any
  sentToBidlogixDateAndTime?: any
  salePrice?: any
  location?: object
  availableAssetActions?: object
  generalInfoSectionApprovalStatus?: string
  valuationSectionApprovalStatus?: string
  biddingDetailsSectionApprovalStatus?: string
  makeOffersAndBidsSectionApprovalStatus?: string
  fssSuppliesSectionApprovalStatus?: string
  complianceSectionApprovalStatus?: string
  bidlogixAuctionId?: any
  bidlogixAuctioneerName?: any
  bidlogixConfiguration?: object
  assetGroup?: object
  assetOrderIndex?: number
  assetDealOrderIndex?: number
  fileGroupId?: any
  lotNumber?: number
  dealName?: string
  rowNumber?: number
}
export interface IGetAssetsResponse {
  size?: number
  number?: number
  first?: boolean
  last?: boolean
  totalElements?: number
  totalPages?: number
  sort?: Array<object>
  content: Array<IAssetResponse>
}

const defaultParams = {
  page: 0,
  size: 1000,
  archived: false,
  sort: 'item.assetOrderIndex'
}

interface IQueryParams {
  deals?: number
  assetGroups?: number
  groupId: string
  page: number
  size: number
  archived: boolean
  sort: string
}

export const getAssets = (mode: string, id: number): Promise<IGetAssetsResponse> => {
  const queryParams: IQueryParams = {
    ...defaultParams,
    ...(mode === 'deal' && { deals: id, sort: 'item.assetDealOrderIndex' }),
    ...(mode === 'catalogue' && { assetGroups: id, sort: 'item.assetOrderIndex' }),
    groupId: params.get('groupId')
  }
  return request
    .get('/assets/api/assets')
    .query(queryParams)
    .then((res) => res.body)
    .catch(() => {
      throw new Error('Failed to get assets')
    })
}
