import React from 'react'
import styled from 'styled-components'
import { Typography } from 'bdx-af-ui/core'
import { colours } from 'bdx-af-ui/theme'

interface ITagThumbnailProps {
  tagText: string
  tagIcon: React.ReactNode
}

const Container = styled.div`
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 25px;
  padding-bottom: 30px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colours.common.gray};
  border-radius: 2px;
  background-color: rgb(244, 244, 236);
  display: flex;
  flex-direction: column;
  justify-content: cebter;
  align-items: center;
  width: 230px;
`
const TagIcon = styled.div`
  padding-bottom: 25px;
`
const TagText = styled(Typography)`
  padding-bottom: 20px;
`

const TagThumbnail: React.FunctionComponent<ITagThumbnailProps> = ({ children, tagIcon, tagText }) => (
  <Container>
    <TagIcon>
      {tagIcon}
    </TagIcon>
    <TagText variant='body2' color='textSecondary'>
      {tagText}
    </TagText>
    {children}
  </Container>
)

export default TagThumbnail
