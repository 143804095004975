import { createSelector } from '@reduxjs/toolkit'

import { IRootState } from '../../rootReducer'
import KEYS from '../../storeKeys'

const assetsSelector = (state: IRootState) => state[KEYS.ASSETS]

export const getAssets = createSelector(
  assetsSelector,
  (state) => state.assets
)

export const getAssetByUuid = (assetUuid: string) => createSelector(
  assetsSelector,
  (state) => state.assets
    .filter(asset => asset.uuid === assetUuid)[0]
)
