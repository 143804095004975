import React from 'react'
import styled from 'styled-components'
import { GridContainer, GridItem, Typography } from 'bdx-af-ui/core'

import TotalItemsCounter from './Counters/TotalItemsCounter'

const Container = styled(GridContainer)`
  margin-bottom: 2rem;
`
const HeaderSection = () => (
  <Container>
    <GridItem xl='auto'>
      <Typography variant='h1' component='div'>UNASSIGNED MEDIA</Typography>
    </GridItem>
    <GridItem xl='auto'>
      <TotalItemsCounter />
    </GridItem>
  </Container>
)

export default HeaderSection
