import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { BaseInput, GridContainer, GridItem } from 'bdx-af-ui/core'
import { Rotate } from 'bdx-af-ui/icons'
import { breakpoints, colours } from 'bdx-af-ui/theme'

import { IMedia } from '../../../api/media'
import { editFileRequest } from '../../../store/ducks/media'
import { getRequestStatus, IRequestStatus } from '../../../store/ducks/apiRequestsStatuses'
import { IRootState } from '../../../store/rootReducer'
import { isFileAnImage } from '../../../utils/fileUpload'

interface IEditAreaProps {
  editFileRequest: typeof editFileRequest
  selectedFile: IMedia
  requestStatus: IRequestStatus
}
interface IRotateButtonProps {
  pending: boolean
}

const RotateButton = styled.div<IRotateButtonProps>`
  margin-left: 1rem;
  padding-top: 1rem;
  cursor: ${({ pending }) => pending ? 'progress' : 'pointer'};
  pointer-events: ${({ pending }) => pending ? 'none' : 'all'};
  @media ${breakpoints.up(breakpoints.breakpoints.lg)} {
    padding-top: 0;
  }
`
const Divider = styled.div`
  height: 0.1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: ${colours.tertiary.main};
`
export const normalizeFileNameForEdit = (fileName: string) => fileName.replace(/[^a-z0-9_-]+/ig, '').slice(0, 255)

export const EditArea: React.FunctionComponent<IEditAreaProps> = ({ editFileRequest, selectedFile, requestStatus }) => {
  const [fileName, setFileName] = React.useState(selectedFile.title)
  React.useEffect(() => {
    setFileName(selectedFile.title)
  }, [selectedFile.title])

  const validateAndSubmit = () => {
    if (fileName.length === 0) {
      return setFileName(selectedFile.title)
    }
    editFileRequest({ id: selectedFile.id, newFileName: fileName })
  }
  const handleFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(normalizeFileNameForEdit(e.target.value))
  }
  const handleFileNameKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') validateAndSubmit()
  }

  const handleRotationRight = () => editFileRequest({ id: selectedFile.id, angle: 90 })
  const handleRotationLeft = () => editFileRequest({ id: selectedFile.id, angle: 270 })
  const isFileImage = isFileAnImage(selectedFile)

  return (
    <GridContainer justify='center' alignItems='center'>
      <GridItem xs={10}>
        <Divider />
      </GridItem>
      <GridItem xs={12} md={12} lg={10}>
        <GridContainer justify='center' alignItems='center'>
          <GridItem xs={12} lg={8}>
            <GridContainer justify='center' alignItems='center'>
              <BaseInput
                value={fileName}
                onChange={handleFileNameChange}
                onBlur={validateAndSubmit}
                onKeyDown={handleFileNameKeyDown}
              />
            </GridContainer>
          </GridItem>
          {isFileImage &&
            <GridItem xs={12} lg={4}>
              <GridContainer justify='center' alignItems='center'>
                <RotateButton onClick={handleRotationRight} pending={requestStatus.isPending} data-testid='rotateRightButton'>
                  <Rotate direction='right' />
                </RotateButton>
                <RotateButton onClick={handleRotationLeft} pending={requestStatus.isPending} data-testid='rotateLeftButton'>
                  <Rotate direction='left' />
                </RotateButton>
              </GridContainer>
            </GridItem>
          }
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

const mapStateToProps = (state: IRootState) => ({
  requestStatus: getRequestStatus(editFileRequest.type)(state)
})
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  editFileRequest
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EditArea)
