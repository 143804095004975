import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { ContentLoader, Typography } from 'bdx-af-ui/core'
import { constants } from 'bdx-af-ui/theme'
import { StringUtils } from 'bdx-af-ui/utils'

import { IRootState } from '../../../store/rootReducer'
import { getAssets, IAsset, fetchAssets } from '../../../store/ducks/assets'
import { getRequestStatus, IRequestStatus } from '../../../store/ducks/apiRequestsStatuses'
import ListItem from './ListItem'
import { getContainerOffsets, IContainerOffsets } from '../../../store/ducks/configs'
import { getMode } from '../../../store/ducks/mode'

interface IAssetsListProps {
  assets: Array<IAsset>
  requestStatus: IRequestStatus
  isExpanded?: boolean
  containerOffsets: IContainerOffsets
  mode: string
}
interface IContainerProps {
  containerOffsets: IContainerOffsets
}
const Container = styled.div<IContainerProps>`
  margin-top: 15px;
  overflow-y: auto;
  height: ${({ containerOffsets }) => `calc(100vh - ${containerOffsets.assetListOffset || constants.DEFAULT_ASSET_LIST_OFFSET})`};
`
const LoaderElement = () => (
  <>
    <rect x='0' y='0' rx='0' ry='0' width='1000' height='80' />
    <rect x='0' y='85' rx='0' ry='0' width='1000' height='80' />
    <rect x='0' y='170' rx='0' ry='0' width='1000' height='80' />
    <rect x='0' y='255' rx='0' ry='0' width='1000' height='80' />
    <rect x='0' y='340' rx='0' ry='0' width='1000' height='80' />
    <rect x='0' y='425' rx='0' ry='0' width='1000' height='80' />
  </>
)

export const AssetsList: React.FunctionComponent<IAssetsListProps> = ({ assets, requestStatus, isExpanded, containerOffsets, mode }) => (
  <Container containerOffsets={containerOffsets}>
    <ContentLoader
      isVisible={requestStatus.isPending}
      hasError={Boolean(requestStatus.error)}
      loaderSvg={<LoaderElement />}
      height='90%'
    >
      {assets.length > 0
        ? assets.map(asset => (
          <ListItem key={asset.uuid} asset={asset} isExpanded={isExpanded} />

        ))
        : <Typography>Please select a {StringUtils.entities.decode(mode)} containing assets to continue.</Typography>
      }
    </ContentLoader >
  </Container >
)

const mapStateToProps = (state: IRootState) => ({
  assets: getAssets(state),
  requestStatus: getRequestStatus(fetchAssets.type)(state),
  containerOffsets: getContainerOffsets(state),
  mode: getMode(state)
})

export default connect(mapStateToProps)(AssetsList)
