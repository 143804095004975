import React, { FunctionComponent, useEffect } from 'react'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useDrop, DropTargetMonitor } from 'react-dnd'
import styled from 'styled-components'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import { Typography } from 'bdx-af-ui/core'
import { breakpoints, colours, constants } from 'bdx-af-ui/theme'

import { uploadRequest, ITags } from '../../../store/ducks/media'
import { SUPPORTED_FILE_TYPES } from '../../../utils/fileUpload'
import BulkActions from '../BulkActions/Container'
import { IRootState } from '../../../store/rootReducer'
import { getContainerOffsets, IContainerOffsets } from '../../../store/ducks/configs'
import UploadModal from './UploadModal'

interface IDropzoneProps {
  uploadRequest: typeof uploadRequest
  containerOffsets: IContainerOffsets
}
interface IDropzoneContainerProps {
  containerOffsets: IContainerOffsets
}
const DropzoneContainer = styled.div<IDropzoneContainerProps>`
  border: 2px dashed ${colours.primary.main};
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: ${({ containerOffsets }) => `calc(100vh - ${containerOffsets.dropzoneOffset || constants.DEFAULT_DROPZONE_OFFSET})`};
  @media ${breakpoints.down(breakpoints.breakpoints.sm)} {
    padding-top: 4rem;
  }
`
const DropzoneText = styled(Typography)`
  padding: 1rem;
`
// !important is used here, because assetflow have global styles for inputs, which need to be overriden
const Input = styled.input`
  display: none !important;
`
const UploadLabel = styled.label`
  text-decoration: none;
  color: ${colours.text.link};
  &:hover {
    cursor: pointer;
  }
  display: inline;
  font-weight: 400;
`

export const Dropzone: FunctionComponent<IDropzoneProps> = ({ children, uploadRequest, containerOffsets }) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const [files, setFiles] = React.useState<FileList>(null)
  const [fileLength, setFileLength] = React.useState(0)
  const inputRef = React.useRef<HTMLInputElement>()
  const onModalConfirm = (matchByFilename: boolean, tags: ITags) => {
    uploadRequest({ files, matchByFilename, tags })
    modalCleanUp()
  }
  const modalCleanUp = () => {
    setFiles(null)
    setFileLength(0)
    setModalOpen(false)
    inputRef.current.value = ''
  }
  const handleManualUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiles(e.target.files)
    setFileLength(e.target.files.length)
  }
  const handleOnDropUpload = (monitor: DropTargetMonitor) => {
    if (monitor) {
      setFiles(monitor.getItem().files)
      setFileLength(monitor.getItem().files.length)
    }
  }
  useEffect(() => {
    if (files) {
      setModalOpen(true)
    }
  }, [files])

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      handleOnDropUpload(monitor)
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  })

  const isActive = canDrop && isOver

  return (
    <DropzoneContainer ref={drop} containerOffsets={containerOffsets}>
      <BulkActions />
      {isActive
        ? <DropzoneText variant='h1' color='textSecondary'>Release to upload</DropzoneText>
        : (
          <DropzoneText variant='h1' color='textSecondary' component='div'>Drop images or
            <UploadLabel htmlFor='files'> open</UploadLabel>
            <Input id='files' type='file' onChange={handleManualUpload} multiple accept={SUPPORTED_FILE_TYPES.join(',')} ref={inputRef} />
          </DropzoneText>
        )
      }
      {children}
      <UploadModal
        isOpen={modalOpen}
        fileLength={fileLength}
        onConfirm={onModalConfirm}
        onClose={modalCleanUp}
      />
    </DropzoneContainer>
  )
}

const mapStateToProps = (state: IRootState) => ({
  containerOffsets: getContainerOffsets(state)
})
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  uploadRequest
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Dropzone)
