import { IRootState } from '../../rootReducer'
import KEYS from '../../storeKeys'
import { createSelector } from '@reduxjs/toolkit'
import { StringUtils } from 'bdx-af-ui/utils'

const dealsSelector = (state: IRootState) => state[KEYS.DEALS]

export const getSelectedDealId = createSelector(
  dealsSelector,
  (state) => state.selectedDealId
)

export const getDeals = createSelector(
  dealsSelector,
  (state) => state.deals
)

export const getSelectedDeal = createSelector(
  dealsSelector,
  getSelectedDealId,
  (state, dealId) => state.deals.find(deal => deal.id === parseInt(dealId))
)

export const getSelectedDealName = createSelector(
  dealsSelector,
  getSelectedDealId,
  (state, dealId) => state.deals.find(deal => deal.id === parseInt(dealId))?.name
)

export const getDealTags = createSelector(
  getDeals,
  (deals) => deals.map(({ uuid, name }) => ({ value: uuid, label: StringUtils.entities.decode(name) }))
)
