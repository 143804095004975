import React from 'react'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import { Button } from 'bdx-af-ui/core'

import DeleteConfirmationModal from './DeleteConfirmationModal'
import { getSelectedFolders, deleteFoldersRequest, IFolder } from '../../../store/ducks/media'

interface IDeleteButtonProps {
  foldersToDelete: Array<IFolder>
  deleteFoldersRequest: typeof deleteFoldersRequest
}

export const DeleteButton: React.FunctionComponent<IDeleteButtonProps> = ({ foldersToDelete, deleteFoldersRequest }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setIsModalOpen(true)
  }
  const handleFolderRemove = () => {
    deleteFoldersRequest({ folders: foldersToDelete })
    setIsModalOpen(false)
  }
  const handleModalClose = () => {
    setIsModalOpen(false)
  }
  const numberOfFilesToDelete = foldersToDelete.reduce((acc, folder) => acc + folder.files.length, 0)

  return (
    <>
      <Button onClick={handleButtonClick} variant='contained' isDisabled={foldersToDelete.length === 0}>
        DELETE ({foldersToDelete.length})
      </Button>
      <DeleteConfirmationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleFolderRemove}
        numberOfFiles={numberOfFilesToDelete}
      />
    </>
  )
}

const mapStateToProps = (state: any) => ({
  foldersToDelete: getSelectedFolders(state)
})
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  deleteFoldersRequest
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DeleteButton)
