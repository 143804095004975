import React from 'react'
import styled from 'styled-components'

import { Typography } from 'bdx-af-ui/core'

interface IGroupIndicatorBackgroundProps {
  filesLength: number
}
const GroupIndicatorText = styled(Typography)`
  position: absolute;
  right: 5px;
  bottom: 8px;
`
const Container = styled.div`
  position: absolute;
  top: 0;
  z-index: -1;
  width: 85%;
  height: 85%;
  left: 15%;
  top: 15%;
  background-color: rgba(236, 236, 236, 1);
`

const GroupIndicatorBackground: React.FunctionComponent<IGroupIndicatorBackgroundProps> = ({ filesLength }) => (
  <Container>
    <GroupIndicatorText variant='subtitle2'>
      + {filesLength - 1} more
    </GroupIndicatorText>
  </Container>
)

export default GroupIndicatorBackground
