import React from 'react'
import styled from 'styled-components'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import { Bin, MagnifyingGlass } from 'bdx-af-ui/icons'
import { colours } from 'bdx-af-ui/theme'

import { resolveEditorPositionAndOpen } from '../../../store/ducks/inlineEditor'
import { deleteFoldersRequest, IFolder } from '../../../store/ducks/media'
import DeleteConfirmationModal from '../BulkActions/DeleteConfirmationModal'

interface IActionsOverlayProps {
  folder?: IFolder
  resolveEditorPositionAndOpen: typeof resolveEditorPositionAndOpen
  deleteFoldersRequest: typeof deleteFoldersRequest
}

export const Container = styled.div`
  background-color: ${colours.background.dark};
  position: absolute;
  bottom: 0;
  width: 100%;
  display: none;
  z-index: 50;
`
const Button = styled.button`
  width: 50%;
  border:0;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  padding: 0.5rem;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`

export const ActionsOverlay: React.FunctionComponent<IActionsOverlayProps> = ({ folder, resolveEditorPositionAndOpen, deleteFoldersRequest }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const handleBinClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setIsModalOpen(true)
  }
  const handleFolderRemove = () => {
    deleteFoldersRequest({ folders: [folder] })
    setIsModalOpen(false)
  }
  const handleModalClose = () => {
    setIsModalOpen(false)
  }
  const handleMagnifyingGlassClick = () => {
    resolveEditorPositionAndOpen({ folder })
  }

  return (
    <Container>
      <Button onClick={handleMagnifyingGlassClick} data-testid='editor-toggle'>
        <MagnifyingGlass />
      </Button>
      <Button onClick={handleBinClick} data-testid='delete-item'>
        <Bin />
      </Button>
      <DeleteConfirmationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleFolderRemove}
        numberOfFiles={folder.files.length}
      />
    </Container>
  )
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  resolveEditorPositionAndOpen,
  deleteFoldersRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(ActionsOverlay)
