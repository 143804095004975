import React, { FunctionComponent, useEffect } from 'react'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { fetchMedia } from '../../../store/ducks/media'
import { fetchAssetGroups } from '../../../store/ducks/assetGroups'
import List from '../List'
import Dropzone from './Dropzone'
import UploadCounter from '../Counters/UploadCounter'
import { fetchDeals } from '../../../store/ducks/deals'
import { IRootState } from '../../../store/rootReducer'
import { getMode, MediaLibraryMode } from '../../../store/ducks/mode'

interface IDropSection {
  fetchMedia: typeof fetchMedia
  fetchAssetGroups: typeof fetchAssetGroups
  fetchDeals: typeof fetchDeals
  mode: string
}

const DropSection: FunctionComponent<IDropSection> = ({ fetchMedia, fetchAssetGroups, fetchDeals, mode }) => {
  useEffect(() => {
    fetchMedia()
    if (mode === MediaLibraryMode.DEAL) {
      fetchDeals()
    } else {
      fetchAssetGroups()
    }
  }, [fetchAssetGroups, fetchMedia, fetchDeals, mode])

  return (
    <DndProvider backend={HTML5Backend}>
      <Dropzone>
        <UploadCounter />
        <List />
      </Dropzone>
    </DndProvider>
  )
}

const mapStateToProps = (state: IRootState) => ({
  mode: getMode(state)
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  fetchMedia,
  fetchAssetGroups,
  fetchDeals
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DropSection)
