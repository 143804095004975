import { put, select, takeLatest } from 'redux-saga/effects'
import { getDeals, IGetDealsResponse } from '../../../api/deals'
import { assignSelectedDealIdFromUrl, fetchDeals, getSelectedDealId, setDeals, setSelectedDealId } from './index'
import { handleAsyncCall } from '../apiRequestsStatuses/sagas'
import { ModeInitialStateParam } from '../mode'

const params = new URLSearchParams(document.location.search.substring(1))

export function* assignSelectedDealIdFromUrlSaga() {
  const dealId = params.get(ModeInitialStateParam.INITIAL_DEAL_ID)
  const selectedDealId = yield select(getSelectedDealId)
  if (dealId && selectedDealId === '') {
    yield put(setSelectedDealId({ id: dealId }))
  }
}

export function* fetchDealsSaga() {
  const response: IGetDealsResponse = yield handleAsyncCall(fetchDeals.type, getDeals)
  yield put(setDeals({ deals: response.content }))
}

export default function* () {
  yield takeLatest(assignSelectedDealIdFromUrl.type, assignSelectedDealIdFromUrlSaga)
  yield takeLatest(fetchDeals.type, fetchDealsSaga)
}
