import React from 'react'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import { Button } from 'bdx-af-ui/core'

import { mergeFolders, getIndexedItems } from '../../../store/ducks/media'

interface IGroupButtonProps {
  mergeFolders: typeof mergeFolders
  isDisabled: boolean
  numberOfSelectedFolders: number
}

export const GroupButton: React.FunctionComponent<IGroupButtonProps> = ({ mergeFolders, isDisabled, numberOfSelectedFolders }) => (
  <Button onClick={() => mergeFolders()} variant='contained' isDisabled={isDisabled}>
    GROUP ({numberOfSelectedFolders})
  </Button>
)

const mapStateToProps = (state: any) => ({
  isDisabled: Object.keys(getIndexedItems(state)).length < 2,
  numberOfSelectedFolders: Object.keys(getIndexedItems(state)).length
})
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  mergeFolders
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(GroupButton)
