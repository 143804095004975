import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getSpacing, GridContainer, GridItem } from 'bdx-af-ui/core'
import { Close } from 'bdx-af-ui/icons'
import { colours } from 'bdx-af-ui/theme'

import { IRootState } from '../../../store/rootReducer'
import MainImage from './MainImage'
import AllImages from './AllImages'
import { getMediaContainerWidth, closeInlineEditor, getIsOpen, getSelectedFile } from '../../../store/ducks/inlineEditor'
import { THUMBNAIL_WIDTH } from '../Thumbnail/Thumbnail'
import EditArea from './EditArea'
import { IMedia } from '../../../api/media'
import IndexNumberCell from './IndexNumberCell'

interface IInlineEditorProps {
  closeInlineEditor?: typeof closeInlineEditor
  mediaContainerWidth?: number
  isOpen?: boolean
  selectedFile: IMedia
}
interface IContainerProps {
  mediaContainerWidth?: number
}
const Container = styled(GridContainer) <IContainerProps>`
  background-color: ${colours.background.dark};
  padding: 16px;
  position: relative;
  width: ${({ mediaContainerWidth }) => {
    const adjustedThumbnailWidth = THUMBNAIL_WIDTH + getSpacing(1, 1)
    const itemsInRow = Math.trunc(mediaContainerWidth / adjustedThumbnailWidth)
    const totalWidth = adjustedThumbnailWidth * itemsInRow - getSpacing(1, 1)
    return `${totalWidth}px`
  }};
`
const CloseButton = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
  border: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
`
const RightContainer = styled(GridContainer)`
  height: 100%;
`

export const InlineEditorContainer: React.FunctionComponent<IInlineEditorProps> = ({ isOpen, closeInlineEditor, mediaContainerWidth, selectedFile }) => (
  isOpen
    ? <GridItem xs={12}>
      <Container mediaContainerWidth={mediaContainerWidth}>
        <CloseButton onClick={() => closeInlineEditor()}>
          <Close fillColor='#EBEAEA' />
        </CloseButton>
        <MainImage selectedFile={selectedFile} />
        <GridItem sm={5} xs={12}>
          <RightContainer direction='column' justify='center'>
            <GridItem xs='auto'>
              <IndexNumberCell />
            </GridItem>
            <GridItem xs='auto'>
              <AllImages />
            </GridItem>
            <GridItem xs='auto'>
              <EditArea selectedFile={selectedFile} />
            </GridItem>
          </RightContainer>
        </GridItem>
      </Container>
    </GridItem>
    : null
)

const mapStateToProps = (state: IRootState) => ({
  mediaContainerWidth: getMediaContainerWidth(state),
  isOpen: getIsOpen(state),
  selectedFile: getSelectedFile(state)
})
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  closeInlineEditor
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(InlineEditorContainer)
