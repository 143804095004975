import React, { useEffect } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { GridContainer, GridItem } from 'bdx-af-ui/core'
import { Spinner } from 'bdx-af-ui/icons'
import { breakpoints, constants } from 'bdx-af-ui/theme'

import DropSection from './Media/Upload/DropSection'
import HeaderSection from './Media/HeaderSection'
import Drawer from './Drawer/DrawerSection'
import { IRootState } from '../store/rootReducer'
import { getIsAppReady, fetchCloudinaryConfig, setContainerOffsets, IContainerOffsets } from '../store/ducks/configs'
import ErrorToast from './Error/ErrorToast'
import { ModeInitialStateParam, initMode } from '../store/ducks/mode'

interface IMainViewProps {
  isAppReady: boolean
  fetchCloudinaryConfig: typeof fetchCloudinaryConfig
  setContainerOffsets: typeof setContainerOffsets
  containerOffsets?: IContainerOffsets
  initMode: typeof initMode
}

const MainContainer = styled(GridContainer)`
  overflow: hidden;
`
const DrawerSection = styled(GridItem)`
  width: ${constants.DRAWER_WIDTH};
  @media ${breakpoints.down(breakpoints.breakpoints.md)} {
    dsiplay: none;
    width: 0;
  }
`
// width: 1%  may looks weird, but it's fix for rare bug when inline editor is open, and we resize window
const MediaSection = styled(GridItem)`
  margin: 1rem;
  width: 1%;
`
const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const params = new URLSearchParams(document.location.search.substring(1))

export const MainView: React.FunctionComponent<IMainViewProps> = ({ isAppReady, fetchCloudinaryConfig, setContainerOffsets, containerOffsets, initMode }) => {
  useEffect(() => {
    if (!isAppReady) {
      if (params.get(ModeInitialStateParam.MODE)) {
        initMode({ mode: params.get(ModeInitialStateParam.MODE) })
      }
      setContainerOffsets(containerOffsets)
      fetchCloudinaryConfig()
    }
  }, [isAppReady])

  return (
    <>
      {isAppReady
        ? (
          <MainContainer>
            <DrawerSection xs='auto'>
              <Drawer />
            </DrawerSection>
            <MediaSection>
              <HeaderSection />
              <DropSection />
            </MediaSection>
          </MainContainer>
        )
        : (
          <IconContainer>
            <Spinner />
          </IconContainer>
        )
      }
      <ErrorToast />
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  isAppReady: getIsAppReady(state)
})
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  fetchCloudinaryConfig,
  setContainerOffsets,
  initMode
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MainView)
