import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import styled from 'styled-components'
import { MinusInCircle } from 'bdx-af-ui/icons'

import { IAssetFile, getAssetByUuid, removeFileFromAssetRequest } from '../../../store/ducks/assets'
import RemoveImageConfirmationModal from './RemoveImageConfirmationModal'
import { IRootState } from '../../../store/rootReducer'

interface IRemoveImageButtonProps {
  assetUuid: string
  assetTitle: string
  isRemovable: boolean
  file: IAssetFile
  removeFileFromAssetRequest: typeof removeFileFromAssetRequest
}

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
`

export const RemoveImageButton: React.FunctionComponent<IRemoveImageButtonProps> = ({ assetUuid, assetTitle, isRemovable, file, removeFileFromAssetRequest }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleIconClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setIsModalOpen(true)
  }
  const handleRemoveImage = () => {
    removeFileFromAssetRequest({ assetUuid, fileId: file.id })
    setIsModalOpen(false)
  }
  const handleModalClose = () => {
    setIsModalOpen(false)
  }
  return (
    <>
      <RemoveImageConfirmationModal
        assetTitle={assetTitle}
        isRemovable={isRemovable}
        imgSrc={`${file.baseUrl}?maxHeight=200&maxWidth=200`}
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleRemoveImage}
      />
      <IconContainer
        data-testid='remove-image-button'
        title='Unassign'
        onClick={handleIconClick}
      >
        <MinusInCircle />
      </IconContainer>
    </>
  )
}

const mapStateToProps = (state: IRootState, props: Partial<IRemoveImageButtonProps>) => ({
  assetTitle: getAssetByUuid(props.assetUuid)(state).title,
  isRemovable: getAssetByUuid(props.assetUuid)(state).assetStatus !== 'SALES_IN_PROGRESS'
})
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  removeFileFromAssetRequest
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RemoveImageButton)
