import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { GridContainer, GridItem, Typography } from 'bdx-af-ui/core'
import { StringUtils } from 'bdx-af-ui/utils'

import { toggleAssetSelection, IAsset } from '../../../store/ducks/assets'
import MainImage from './MainImage'
import AllImages from './AllImages'

interface IListItemProps {
  asset: IAsset
  toggleAssetSelection: typeof toggleAssetSelection
  isExpanded?: boolean
}
interface IContainerProps {
  isSelected?: boolean
}

const MainContainer = styled.div<IContainerProps>`
  background-color: ${({ isSelected }) => isSelected ? 'rgba(254, 254, 254, 1)' : 'rgba(246, 246, 246, 1)'};
  padding: 10px;
  margin-bottom: 0.3rem;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isSelected }) => isSelected ? 'rgba(246, 246, 246, 1)' : 'rgba(246, 246, 246, 0)'};
`
const ClickableContainer = styled(GridContainer) <IContainerProps>`
  cursor: pointer;
`

export const ListItem: React.FunctionComponent<IListItemProps> = ({ asset, toggleAssetSelection, isExpanded }) => (
  <MainContainer isSelected={asset.isSelected}>
    <ClickableContainer data-testid={`row-item-${asset.rowNumber}`} onClick={() => toggleAssetSelection({ uuid: asset.uuid })} wrap='nowrap' spacing={1}>
      <GridItem xs={1}>
        <Typography variant='body2' align='left'>
          {asset.rowNumber}
        </Typography>
      </GridItem>
      <GridItem xs='auto'>
        <MainImage files={asset.files} uuid={asset.uuid}/>
      </GridItem>
      <GridItem xs='auto'>
        <Typography variant='body2' align='left'>
          {StringUtils.entities.decode(asset.title)}
        </Typography>
      </GridItem>
    </ClickableContainer>
    {asset.isSelected && <AllImages assetUuid={asset.uuid} files={asset.files} isExpanded={isExpanded} />}
  </MainContainer>
)

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  toggleAssetSelection
}, dispatch)

export default connect(null, mapDispatchToProps)(ListItem)
