import request from 'superagent'

const params = new URLSearchParams(document.location.search.substring(1))

export interface ISort {
  ascending: boolean
  direction: string
  ignoreCase: boolean
  nullHandling: string
  property: string
}
export interface IAssetGroup {
  archived?: boolean
  assetGroupSize?: number
  assetIds?: number[]
  assetOrderIndex?: number
  availableGroupActions?: {}
  bidlogixConfigurationId?: number
  createdDate?: number
  createUser?: {}
  id: number
  name: string
  updateDate?: number
  updateUser?: number | null
  uuid: string
}
export interface IGetAssetGroups {
  size?: number
  totalPages?: number
  totalElements?: number
  sort?: ISort[]
  number?: number
  last?: boolean
  first?: boolean
  content: IAssetGroup[]
}

export const getAssetGroups = (): Promise<IGetAssetGroups> => (
  request
    .get(`/assets/api/assetGroups?groupId=${params.get('groupId')}&page=0&size=1000&archived=false&sort=item.name%2Casc`)
    .send()
    .then((res) => res.body)
    .catch(() => {
      throw new Error('Failed to get asset groups')
    })
)
