import React from 'react'
import styled from 'styled-components'
import { Button, GridContainer, ImageLoader, Modal, Paper, Typography } from 'bdx-af-ui/core'
import { Close } from 'bdx-af-ui/icons'

interface IRemoveImageConfirmationModal {
  assetTitle: string
  imgSrc: string
  isOpen: boolean
  isRemovable: boolean
  onConfirm: () => void
  onClose: () => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 4rem;
  position: relative;
  text-align: center;
`
const CloseButton = styled.button`
  position: absolute;
  right: 1rem;
  top: 1rem;
  border: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
`
const ImgContainer = styled.div`
  margin-bottom: 1rem;
  min-height: 50%;
  position: relative;
  width: 100%;
  max-width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const RemoveImageConfirmationModal: React.FunctionComponent<IRemoveImageConfirmationModal> = ({ assetTitle, isRemovable, imgSrc, isOpen, onConfirm, onClose }) => {
  const handleConfirm = () => {
    onConfirm()
  }
  const handleClose = () => {
    onClose()
  }

  return (
    isOpen && <Modal open={isOpen} onClose={handleClose}>
      <Paper>
        <Container>
          <CloseButton onClick={handleClose}>
            <Close />
          </CloseButton>
          <Typography variant='h2' marginBottom='1rem'>{`Are you sure you want to unassign this file from ${assetTitle}?`}</Typography>
          <ImgContainer>
            <ImageLoader
              width='100%'
              height='100%'
              positioning={{ top: 0, right: 0, bottom: 0, left: 0 }}
              image={
                <img
                  src={imgSrc}
                />
              }
            />
          </ImgContainer>
          <GridContainer justify={'center'}>
            <Button variant='text' size='large' onClick={handleClose}>Cancel</Button>
            <Button
              data-testid='remove-confirmation-button'
              variant='contained'
              size='large'
              onClick={handleConfirm}
              isDisabled={!isRemovable}
              disabled={!isRemovable}
              title={isRemovable ? 'Confirm unassign' : 'Can\'t unassign while sales is in progress'}
            >
                Unassign
            </Button>
          </GridContainer>
        </Container>
      </Paper>
    </Modal>
  )
}

export default RemoveImageConfirmationModal
