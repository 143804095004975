enum KEYS {
  MEDIA = 'MEDIA',
  ASSET_GROUPS = 'ASSET_GROUPS',
  DEALS = 'DEALS',
  INLINE_EDITOR = 'INLINE_EDITOR',
  ASSETS = 'ASSETS',
  API_REQUESTS_STATUSES = 'API_REQUESTS_STATUSES',
  CONFIGS = 'CONFIGS',
  MODE = 'MODE'
}

export default KEYS
