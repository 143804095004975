import { IRootState } from '../../rootReducer'
import KEYS from '../../storeKeys'
import { createSelector } from '@reduxjs/toolkit'
import { getSelectedDeal, IDeal } from '../deals'
import { getSelectedAssetGroup } from '../assetGroups'
import { MediaLibraryMode } from './index'
import { IAssetGroup } from '../../../api/assetGroups'
const mode = (state: IRootState) => state[KEYS.MODE]

export const getMode = createSelector(
  mode,
  (state) => state.mode
)

export const isInCatalogueMode = createSelector(getMode, (mode: string) => mode === MediaLibraryMode.CATALOGUE)
export const isInDealMode = createSelector(getMode, (mode: string) => mode === MediaLibraryMode.DEAL)

const combiner = (dealMode: boolean, deal: IDeal, assetGroup: IAssetGroup) => dealMode ? deal : assetGroup
export const getSelectedObject = createSelector(isInDealMode, getSelectedDeal, getSelectedAssetGroup, combiner)

export const getSelectedObjectName = createSelector(getSelectedObject, (selectedObject: IDeal | IAssetGroup) => selectedObject?.name)

export const getSelectedItemId = createSelector(
  mode,
  (state) => state.selectedItemId
)

export const getModeLabel = createSelector(
  mode,
  (state) => state.label
)

export const getSelectionItems = createSelector(
  mode,
  (state) => state.selectionItems
)

export const getSelectedOption = createSelector(
  mode,
  (state) => state.selectionItems.find(item => item.value === Number(state.selectedItemId))
)
