import React from 'react'
import styled from 'styled-components'

import { ImageLoader } from 'bdx-af-ui/core'

interface IImageProps {
  isMultiple?: boolean
  url?: string
  handleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}
interface IStyledImageProps {
  isMultiple?: boolean
  isVisible?: boolean
}

const StyledImage = styled.img<IStyledImageProps>`
  width: ${({ isMultiple }) => isMultiple ? '80%' : '100%'};
  height: ${({ isMultiple }) => isMultiple ? '80%' : '100%'};
  border-right: ${({ isMultiple }) => isMultiple ? 'solid 2px white' : ''};
  border-bottom: ${({ isMultiple }) => isMultiple ? 'solid 2px white' : ''};
  background-color: ${({ isMultiple }) => isMultiple ? 'rgba(236, 236, 236, 1)' : ''};
  object-fit: contain;
`

const Image: React.FunctionComponent<IImageProps> = ({ handleClick, isMultiple, url }) => (
  <>
    <ImageLoader
      image={<StyledImage
        src={url}
        onClick={handleClick}
        isMultiple={isMultiple}
        draggable={false}
        data-testid='img-selector'
      />}
    />
  </>
)

export default Image
