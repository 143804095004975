import React from 'react'
import { connect } from 'react-redux'
import { GridContainer, Typography } from 'bdx-af-ui/core'

import { IRootState } from '../../../store/rootReducer'
import { getExpandedFolder, getIndexNumber } from '../../../store/ducks/media'

interface IIndexNumberCell {
  indexNumber: number | undefined
}

export const IndexNumberCell: React.FunctionComponent<IIndexNumberCell> = ({ indexNumber }) => (
  <GridContainer direction='column' alignItems='center'>
    <Typography color='white' variant='body2' marginBottom='2rem'>
      ITEM {indexNumber !== undefined ? (indexNumber + 1) : null}
    </Typography>
  </GridContainer>
)

const mapStateToProps = (state: IRootState) => ({
  indexNumber: getIndexNumber(getExpandedFolder(state).id)(state)
})

export default connect(mapStateToProps)(IndexNumberCell)
