import { createSelector } from '@reduxjs/toolkit'

import { IRootState } from '../../rootReducer'
import KEYS from '../../storeKeys'
import { getExpandedFolder } from '../media/selectors'

const inlineEditorSelector = (state: IRootState) => state[KEYS.INLINE_EDITOR]

export const getEditorPositionIndex = createSelector(
  inlineEditorSelector,
  (state) => state.editorPositionIndex
)
export const getMediaContainerWidth = createSelector(
  inlineEditorSelector,
  (state) => state.mediaContainerWidth
)
export const getSelectedFile = createSelector(
  inlineEditorSelector, getExpandedFolder,
  (state, folder) => folder.files.find(file => file.id === state.selectedFileId)
)
export const getIsOpen = createSelector(
  inlineEditorSelector,
  (state) => state.isOpen
)
