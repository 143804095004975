import styled from 'styled-components'

const StyledPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  padding: 1rem;
  background-color: rgba(236,236,236,1);
`

export default StyledPlaceholder
