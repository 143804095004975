import { put, takeLatest } from 'redux-saga/effects'

import { getCloudinaryConfig } from '../../../api/configs'
import { fetchCloudinaryConfig, setCloudinaryConfig } from './index'
import { handleAsyncCall } from '../apiRequestsStatuses/sagas'

export function* fetchCloudinaryConfigSaga() {
  const config = yield handleAsyncCall(fetchCloudinaryConfig.type, getCloudinaryConfig)
  yield put(setCloudinaryConfig({ config }))
}

export default function* () {
  yield takeLatest(fetchCloudinaryConfig.type, fetchCloudinaryConfigSaga)
}
