import React from 'react'
import styled from 'styled-components'
import { GridContainer, GridItem } from 'bdx-af-ui/core'
import { breakpoints } from 'bdx-af-ui/theme'

import GroupButton from './GroupButton'
import SelectAllButton from './SelectAllButton'
import DeleteButton from './DeleteButton'
import AssignByIndexNumberButton from './AssignByIndexNumberButton'
import UngroupButton from './UngroupButton'

const Container = styled(GridContainer)`
  position: absolute;
  top: -15px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 0.8rem;
  @media ${breakpoints.down(breakpoints.breakpoints.sm)} {
    font-size: 0.7rem;
  }
`
const Item = styled(GridItem)`
  margin-left: auto;
  @media ${breakpoints.down(breakpoints.breakpoints.sm)} {
    margin-left: unset;
  }
`

const BulkActions = () => (
  <Container justify='space-between' spacing={1}>
    <GridItem sm='auto'>
      <SelectAllButton />
    </GridItem>
    <Item sm='auto'>
      <AssignByIndexNumberButton />
    </Item>
    <GridItem sm='auto'>
      <UngroupButton />
    </GridItem>
    <GridItem sm='auto'>
      <GroupButton />
    </GridItem>
    <GridItem sm='auto'>
      <DeleteButton />
    </GridItem>
  </Container>
)

export default BulkActions
