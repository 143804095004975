import { createAction, createReducer, ActionCreatorWithPayload, ActionCreatorWithoutPayload, PayloadAction } from '@reduxjs/toolkit'
import { ICloudinaryConfig } from '../../../api/configs'

export interface IConfigsState {
  cloudinary: ICloudinaryConfig
  containerOffsets: IContainerOffsets
}
export interface ISetCloudinaryConfig {
  config: ICloudinaryConfig
}
export interface IContainerOffsets {
  assetListOffset?: string
  dropzoneOffset?: string
}

export const fetchCloudinaryConfig: ActionCreatorWithoutPayload = createAction('fetchCloudinaryConfig')
export const setCloudinaryConfig: ActionCreatorWithPayload<ISetCloudinaryConfig> = createAction('setCloudinaryConfig')
export const setContainerOffsets: ActionCreatorWithPayload<IContainerOffsets> = createAction('setContainerOffsets')

const initialState: IConfigsState = {
  cloudinary: null,
  containerOffsets: {}
}

const reducer = createReducer(initialState, {
  [setCloudinaryConfig.type]: (state, { payload: { config } }: PayloadAction<ISetCloudinaryConfig>) => {
    state.cloudinary = config
  },
  [setContainerOffsets.type]: (state, { payload }: PayloadAction<IContainerOffsets>) => {
    state.containerOffsets = payload
  }
})

export * from './selectors'
export default reducer
