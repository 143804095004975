import React from 'react'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, Typography } from 'bdx-af-ui/core'
import { Spinner } from 'bdx-af-ui/icons'
import { colours } from 'bdx-af-ui/theme'

import { IFolder, deleteFolders } from '../../../store/ducks/media'
import { IRequestStatus } from '../../../store/ducks/apiRequestsStatuses'

interface IStatusOverlayProps {
  folder: IFolder
  requestStatus: IRequestStatus
  deleteFolders: typeof deleteFolders
}

const Container = styled.div`
  position: absolute;
  left: 0;
  right:0;
  top: 0;
  bottom: 0;
  background-color: rgba(256, 256, 256, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 99999;
  display: flex;
  cursor: default;
`
const Error = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  background-color: ${colours.text.error};
  width: 100%;
  height: 100%;
  word-wrap: break-word;
`

export const StatusOverlay: React.FunctionComponent<IStatusOverlayProps> = ({ folder, requestStatus, deleteFolders }) => {
  const [isVisible, setIsVisible] = React.useState(false)
  const { hasError, errorText } = folder
  const { isPending, error } = requestStatus
  React.useEffect(() => {
    setIsVisible(hasError || isPending || Boolean(error))
  }, [hasError, isPending, error])
  const handleDismiss = () => {
    setIsVisible(false)
    if (folder.isPlaceholder) {
      deleteFolders({ folders: [folder] })
    }
  }

  return (
    isVisible
      ? <Container>
        {
          hasError || error
            ? <Error>
              <Typography
                variant='body2'
                color='white'
                align='center'>
                {errorText || error || 'An error occured.'}
              </Typography>
              <Button variant='text' onClick={handleDismiss}>
                <Typography variant='body2' color='white'>
                  DISMISS
                </Typography>
              </Button>
            </Error>
            : <Spinner />
        }
      </Container>
      : null
  )
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  deleteFolders
}, dispatch)

export default connect(null, mapDispatchToProps)(StatusOverlay)
