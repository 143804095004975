import React, { useEffect, useState } from 'react'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import { Button } from 'bdx-af-ui/core'

import { IRootState } from '../../../store/rootReducer'
import { selectAllFolders, getIndexedItems, getSelectableFolders, IFolder, IIndexedItems } from '../../../store/ducks/media'

interface ISelectAllButtonProps {
  selectAllFolders: typeof selectAllFolders
  folders: Array<IFolder>
  indexedItems: IIndexedItems
}

export const SelectAllButton: React.FunctionComponent<ISelectAllButtonProps> = ({ folders, indexedItems, selectAllFolders }) => {
  const [allSelected, setAllSelected] = useState(false)
  useEffect(() => {
    setAllSelected(
      (Object.keys(indexedItems).length === folders.filter(folder => !folder.isPlaceholder).length) && folders.length > 0
    )
  }, [indexedItems, folders])
  const handleSelect = () => {
    selectAllFolders({ allSelected })
  }
  return (
    <Button
      onClick={handleSelect}
      variant='contained'
    >
      {allSelected ? 'DESELECT ALL' : 'SELECT ALL ITEMS'} ({folders.length})
    </Button>
  )
}

const mapStateToProps = (state: IRootState) => ({
  folders: getSelectableFolders(state),
  indexedItems: getIndexedItems(state)
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  selectAllFolders
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SelectAllButton)
