import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { NavigationUtils, StringUtils, UploadUtils } from 'bdx-af-ui/utils'

import {
  deleteMedia,
  editMedia,
  getMedia,
  getMediaSignature,
  IGetMedia,
  IGetMediaSignatureResponse,
  IMedia,
  IServerUploadResponse,
  IUploadMediaToClodinaryResponse,
  uploadMediaToCloudinary,
  uploadMediaToServer
} from '../../../api/media'
import {
  AssignFileRequest,
  assignFilesToAssets,
  IAssignFile,
  IAssignFilesToAssetsResponse,
  IAssignmentStatus,
  INewAssetAssignment,
  IValidatedFileName,
  validateAssignByFilename
} from '../../../api/assignments'
import {
  addUploadPlaceholders,
  assigningFoldersByIndexNumberRequest,
  deleteFolders,
  deleteFoldersRequest,
  editFile,
  editFileRequest,
  fetchMedia,
  getFolders,
  getTotalItemsLength,
  IAssignFoldersRequest,
  IDeleteFoldersRequest,
  IEditFileRequest,
  IExtendedFile,
  IFolder,
  IIndexedItems,
  incrementInitialyUploadedCounterBy,
  incrementUploadedCounter,
  IRemoveFileFromFolder,
  ITags,
  IUploadRequestAction,
  IUploadStatus,
  mergeFolders,
  removeFileFromFolder,
  removeFileFromFolderRequest,
  resetSelectedFolders,
  resetUploadCounters,
  setFileBaseUrl,
  setFolders,
  setLeadImage,
  toggleFolderSelection,
  transformPlaceholder,
  unmergeFolders,
  uploadRequest
} from './index'
import { getIndexedItems, getInitialyUploadedCounter, getUploadedCounter } from './selectors'
import { fetchAssets, getAssets, IAsset } from '../assets'
import { closeInlineEditor, getSelectedFile, resolveEditorPositionIndex, setFileSelected } from '../inlineEditor'
import { checkFileAndCompress, getUnsupportedError, isFileSupported } from '../../../utils/fileUpload'
import { handleAsyncCall } from '../apiRequestsStatuses/sagas'
import { setReqeustPending, setRequestError } from '../apiRequestsStatuses'
import { getCloudinaryConfig } from '../configs'
import { getMode, getSelectedObject, isInCatalogueMode } from '../mode'
import { getSelectedCatalogueUuid } from '../assetGroups'
import { IDeal } from '../deals'

export function* fetchItemsSaga() {
  const response: IGetMedia = yield handleAsyncCall(fetchMedia.type, getMedia)
  yield put(setFileBaseUrl({ fileBaseUrl: response.fileBaseUrl }))
  yield put(setFolders({ media: response.files }))
}

export function* uploadFile(file: File, folderId: string, tags: ITags, assetId: string, uploadStatus: IUploadStatus) {
  try {
    if (!uploadStatus.shouldUpload) {
      throw new Error(uploadStatus.reason || '')
    }
    const createdAt = Date.now()
    const base64File = yield UploadUtils.toBase64(file)
    const signatureResponse: IGetMediaSignatureResponse = yield getMediaSignature(file)
    if (!signatureResponse.success) {
      throw new Error(signatureResponse.message)
    }
    const cloudinaryConfig = yield select(getCloudinaryConfig)
    const uploadedToCloudinary: IUploadMediaToClodinaryResponse = yield uploadMediaToCloudinary(
      base64File, signatureResponse.model.data, signatureResponse.model.url, file.type, cloudinaryConfig
    )
    const apiRes: IServerUploadResponse = yield call(uploadMediaToServer, uploadedToCloudinary, tags.catalogueTag, assetId, createdAt)
    if (assetId) {
      yield put(deleteFolders({ folders: [{ id: folderId }] }))
    } else {
      yield put(transformPlaceholder({
        folderId,
        success: true,
        fileId: apiRes.model[0].id,
        type: apiRes.model[0].mimeType,
        title: apiRes.model[0].title
      }))
    }
    yield put(setReqeustPending({ key: folderId, pending: false }))
  } catch (e) {
    yield put(transformPlaceholder({ success: false, folderId, error: e.message }))
    yield put(setReqeustPending({ key: folderId, pending: false }))
  }
  yield put(incrementUploadedCounter())
  yield call(shouldBlockNavigation)
}

export function* prepareFilesForUpload(files: Array<File>, matchByFilename: boolean) {
  interface IValidatedNames {
    [key: string]: IValidatedFileName
  }

  let validatedFilenames: IValidatedNames = {}
  const catalogueMode = yield select(isInCatalogueMode)

  if (catalogueMode && matchByFilename) {
    const filenames = Object.values(files).map(file => ({ filename: file.name }))
    const catalogueUuid = yield select(getSelectedCatalogueUuid)
    const validatedFileCall = yield handleAsyncCall('validateFilename', validateAssignByFilename, catalogueUuid, filenames)
    validatedFilenames = validatedFileCall.reduce((acc: IValidatedNames, curr: IValidatedFileName) => {
      acc[curr.filename] = curr
      return acc
    }, {})
  }
  const preparedFiles = Object.values(files)
    .map((file: IExtendedFile) => {
      const fileSupported = isFileSupported(file)
      const shouldUpload = (validatedFilenames[file.name]?.status !== 'unsuccessful' && fileSupported) || (!matchByFilename && fileSupported)
      let reason = null
      if (!shouldUpload) {
        reason = !fileSupported ? getUnsupportedError(file) : validatedFilenames[file.name]?.reason
      }
      file.folderId = StringUtils.getUUID()
      file.assetId = validatedFilenames[file.name]?.assetUuid || null
      file.uploadStatus = { shouldUpload, reason }
      return file
    })
    .sort((a, b) => {
      if (validatedFilenames[a.name]?.position && validatedFilenames[b.name]?.position) return validatedFilenames[a.name].position - validatedFilenames[b.name].position
      else return 0
    })
  return {
    files: preparedFiles
  }
}

export function* uploadFilesSaga(action: PayloadAction<IUploadRequestAction>) {
  yield call(NavigationUtils.warnBeforePageLeave)
  const filesArray = Array.from(action.payload.files)
  const preparedFileInfo = yield prepareFilesForUpload(filesArray, action.payload.matchByFilename)
  yield put(addUploadPlaceholders({ files: preparedFileInfo.files }))
  yield put(incrementInitialyUploadedCounterBy((preparedFileInfo.files.length)))
  yield put(resolveEditorPositionIndex())
  for (const file of preparedFileInfo.files) {
    yield put(setReqeustPending({ key: file.folderId, pending: true }))
  }

  for (const file of preparedFileInfo.files) {
    const compressed = yield call(checkFileAndCompress, file, { quality: 0.7 })
    action.payload.matchByFilename
      ? yield call(uploadFile, compressed, file.folderId, action.payload.tags, file.assetId, file.uploadStatus)
      : yield fork(uploadFile, compressed, file.folderId, action.payload.tags, file.assetId, file.uploadStatus)
  }
  if (action.payload.matchByFilename) {
    yield put(fetchAssets())
    yield call(shouldBlockNavigation)
  }
}

export function* mergeFoldersSaga() {
  yield put(closeInlineEditor())
  yield call(shouldBlockNavigation)
}

export function* unmergeFoldersSaga() {
  yield put(closeInlineEditor())
  yield put(resetSelectedFolders())
  yield call(shouldBlockNavigation)
}

export function* shouldBlockNavigation() {
  const totalItemsLength = yield select(getTotalItemsLength)
  const folders = yield select(getFolders)
  const initialyUploaded = yield select(getInitialyUploadedCounter)
  const uploaded = yield select(getUploadedCounter)
  const allUploaded = initialyUploaded === uploaded
  if (allUploaded) yield put(resetUploadCounters())
  if ((totalItemsLength > folders.length) || !allUploaded) {
    yield call(NavigationUtils.warnBeforePageLeave)
  } else {
    yield call(NavigationUtils.removeWarnBeforePageLeave)
  }
}

export function* deleteFoldersSaga({ payload: { folders } }: PayloadAction<IDeleteFoldersRequest>) {
  yield put(deleteFolders({ folders }))
  const indexedFolders = yield select(getIndexedItems)
  for (let i = 0; i < folders.length; i++) {
    if (folders[i].isExpanded) {
      yield put(closeInlineEditor())
    }
    if (indexedFolders[folders[i].id] !== undefined) {
      yield put(toggleFolderSelection({ folderId: folders[i].id }))
    }
    for (const file of folders[i].files) {
      yield fork(deleteMedia, file)
    }
  }
  yield put(resolveEditorPositionIndex())
  yield call(shouldBlockNavigation)
}

export function* assignFoldersByIndexNumberSaga({ payload: { folders } }: PayloadAction<IAssignFoldersRequest>) {
  const assets: Array<IAsset> = yield select(getAssets)
  const indexedFolders: IIndexedItems = yield select(getIndexedItems)
  const selectedItem: IAsset | IDeal = yield select(getSelectedObject)
  const mode: string = yield select(getMode)

  const request = new AssignFileRequest()
  request.target = selectedItem.id
  request.mode = mode.toUpperCase()

  let newAssetRowNumber = assets.length > 0 ? assets.length + 1 : 1
  for (const folder of folders) {
    const indexNumber = indexedFolders[folder.id] + 1
    const matchingAsset = assets.find(asset => asset.rowNumber === indexNumber)

    yield put(setReqeustPending({ key: folder.id, pending: true }))
    yield put(toggleFolderSelection({ folderId: folder.id }))
    if (folder.isExpanded) {
      yield put(closeInlineEditor())
    }

    if (matchingAsset) {
      folder.files.forEach(file => {
        request.existingAssets.push({
          fileId: file.id,
          position: file.position,
          assetId: matchingAsset.uuid,
          folderId: folder.id
        })
      })
    } else {
      const newAsset: INewAssetAssignment = {
        items: [],
        folderId: folder.id
      }
      folder.files.forEach(file => {
        newAsset.position = newAssetRowNumber
        newAsset.items.push({
          fileId: file.id,
          position: file.position
        })
      })
      request.newAssets.push(newAsset)
      newAssetRowNumber++
    }
  }

  if (request.hasItems()) {
    const result: IAssignFilesToAssetsResponse = yield call(assignFilesToAssets, request)
    yield call(processAssignmentByIndexNumberResult, result, request)
    yield call(shouldBlockNavigation)
  }
}

export function* processAssignmentByIndexNumberResult(result: IAssignFilesToAssetsResponse, request: AssignFileRequest) {
  const findInExisting = (existingAssets: Array<IAssignFile>, element: IAssignmentStatus): string => existingAssets.find(item => item.fileId === element.FileId)?.folderId
  const findInNew = (newAssets: Array<INewAssetAssignment>, element: IAssignmentStatus): string => newAssets.find(newAsset => newAsset.items.find(item => item.fileId === element.FileId))?.folderId

  yield put(fetchAssets())
  yield put(resolveEditorPositionIndex())
  const successfulList = result.listOfSuccessful.map(element => ({
    id: findInExisting(request.existingAssets, element) || findInNew(request.newAssets, element)
  }))
  const unsuccessfulList = result.listOfUnsuccessful.map(element => ({
    ...element,
    folderId: findInExisting(request.existingAssets, element) || findInNew(request.newAssets, element)
  }))
  yield put(deleteFolders({ folders: successfulList }))
  for (const result of unsuccessfulList) {
    yield put(setReqeustPending({ key: result.folderId, pending: false }))
    yield put(setRequestError({ key: result.folderId, error: result.Reason }))
  }
}

export function* editFileSaga(action: PayloadAction<IEditFileRequest>) {
  yield handleAsyncCall(editFileRequest.type, editMedia, action.payload.id, action.payload.angle, action.payload.newFileName)
  const editPayload: Partial<IMedia> = {
    id: action.payload.id
  }
  const shouldRefreshImage = action.payload.angle !== undefined
  if (shouldRefreshImage) {
    editPayload.updatedDate = Date.now().toString()
  }
  const shouldRefreshFileName = action.payload.newFileName !== undefined
  if (shouldRefreshFileName) {
    editPayload.title = action.payload.newFileName
  }
  yield put(editFile({ file: editPayload }))
}

export function* removeFileFromFolderSaga({ payload: { folderId, fileId } }: PayloadAction<IRemoveFileFromFolder>) {
  const inlineEdidtorSelectedFile: IMedia = yield select(getSelectedFile)
  const folders: Array<IFolder> = yield select(getFolders)
  const folder = folders.find(folder => folder.id === folderId)
  const newSelectedAndLeadFile = folder.files.find(file => fileId !== file.id)
  if (fileId === inlineEdidtorSelectedFile.id) {
    yield put(setFileSelected({ id: newSelectedAndLeadFile.id }))
  }
  if (fileId === folder.files[0].id) {
    yield put(setLeadImage({ folderId, leadImageId: newSelectedAndLeadFile.id }))
  }
  yield put(removeFileFromFolder({ folderId, fileId }))
  yield put(resolveEditorPositionIndex())
  yield call(shouldBlockNavigation)
}

export default function* () {
  yield takeLatest(fetchMedia.type, fetchItemsSaga)
  yield takeEvery(uploadRequest.type, uploadFilesSaga)
  yield takeEvery(mergeFolders.type, mergeFoldersSaga)
  yield takeEvery(unmergeFolders.type, unmergeFoldersSaga)
  yield takeEvery(deleteFoldersRequest.type, deleteFoldersSaga)
  yield takeEvery(assigningFoldersByIndexNumberRequest.type, assignFoldersByIndexNumberSaga)
  yield takeEvery(editFileRequest.type, editFileSaga)
  yield takeEvery(removeFileFromFolderRequest.type, removeFileFromFolderSaga)
}
