import { all } from 'redux-saga/effects'

import mediaSaga from './ducks/media/sagas'
import assetGroupsSaga from './ducks/assetGroups/sagas'
import inlineEditorSaga from './ducks/inlineEditor/sagas'
import assetsSaga from './ducks/assets/sagas'
import configsSaga from './ducks/configs/sagas'
import dealsSaga from './ducks/deals/sagas'
import modeSaga from './ducks/mode/sagas'

export default function * () {
  yield all([
    mediaSaga(),
    assetGroupsSaga(),
    inlineEditorSaga(),
    assetsSaga(),
    configsSaga(),
    dealsSaga(),
    modeSaga()
  ])
}
