import React from 'react'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import { Button } from 'bdx-af-ui/core'

import { unmergeFolders, getIndexedItems, getSelectableFolders, IFolder, IIndexedItems } from '../../../store/ducks/media'

interface IUngroupButtonProps {
  unmergeFolders: typeof unmergeFolders
  folders: Array<IFolder>
  indexedItems: IIndexedItems
}

export const UngroupButton: React.FunctionComponent<IUngroupButtonProps> = ({ unmergeFolders, folders, indexedItems }) => {
  const unmergableFoldersLength = folders.filter(folder => folder.files.length > 1 && indexedItems[folder.id] !== undefined).length

  return (
    <Button onClick={() => unmergeFolders()} variant='contained' isDisabled={unmergableFoldersLength === 0}>
      UNGROUP ({unmergableFoldersLength})
    </Button>
  )
}

const mapStateToProps = (state: any) => ({
  folders: getSelectableFolders(state),
  indexedItems: getIndexedItems(state)
})
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  unmergeFolders
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UngroupButton)
