import { createAction, createReducer, ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit'
import { fetchMedia } from '../media'

export interface IApiRequestsStatuses {
  pending: {
    [key: string]: boolean
  }
  error: {
    [key: string]: string
  }
}
interface ISetReqeustPending {
  key: string
  pending: boolean
}
interface ISetReqeustError {
  key: string
  error: string
}

export const setReqeustPending: ActionCreatorWithPayload<ISetReqeustPending> = createAction('setReqeustPending')
export const setRequestError: ActionCreatorWithPayload<ISetReqeustError> = createAction('setRequestError')

const initialState: IApiRequestsStatuses = {
  pending: {
    [fetchMedia.type]: true
  },
  error: {}
}

const reducer = createReducer(initialState, {
  [setReqeustPending.type]: (state, { payload }: PayloadAction<ISetReqeustPending>) => {
    state.pending[payload.key] = payload.pending
  },
  [setRequestError.type]: (state, { payload }: PayloadAction<ISetReqeustError>) => {
    state.error[payload.key] = payload.error
  }
})

export * from './selectors'
export default reducer
