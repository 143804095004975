import { put, takeLatest, select } from 'redux-saga/effects'
import { getSpacing } from 'bdx-af-ui/core'

import { THUMBNAIL_WIDTH } from '../../../components/Media/Thumbnail/Thumbnail'
import {
  resolveEditorPositionIndex, getMediaContainerWidth, setEditorPositionIndex,
  openInlineEditor, resolveEditorPositionAndOpen, closeInlineEditor,
  IResolveEditorPositionAndOpen, updateAndReorderFiles, setFileSelected, IUpdateAndReorderFiles
} from './index'
import { getFolders, setExpandedFolder, IFolder, setLeadImage, reorderFiles } from '../media'
import { ArrayUtils } from 'bdx-af-ui/utils'
import { PayloadAction } from '@reduxjs/toolkit'

export function* resolveEditorPositionSaga() {
  const folders: Array<IFolder> = yield select(getFolders)
  const containerWidth: number = yield select(getMediaContainerWidth)
  const adjustedThumbnailWidth = THUMBNAIL_WIDTH + getSpacing(1, 1)
  const itemsInRow = Math.trunc(containerWidth / adjustedThumbnailWidth) || 1
  const foldersChunkedIntoRows = ArrayUtils.chunkArray<IFolder>(folders, itemsInRow)
  let positionInRow: number
  foldersChunkedIntoRows
    .forEach((folderRow, rowIndex) => folderRow
      .forEach(folder => {
        if (folder.isExpanded) {
          positionInRow = rowIndex
        }
      }))
  const newIndex = foldersChunkedIntoRows.reduce((acc, row, index) => {
    if (index > positionInRow) {
      return acc
    }
    acc = acc + row.length
    return acc
  }, -1)

  yield put(setEditorPositionIndex({ index: newIndex }))
}

export function* closeInlineEditorSaga() {
  yield put(setExpandedFolder({ id: '' }))
}

export function* resolveEditorPositionAndOpenSaga(action: PayloadAction<IResolveEditorPositionAndOpen>) {
  yield put(setExpandedFolder({ id: action.payload.folder.id }))
  yield put(setFileSelected({ id: action.payload.folder.files[0].id }))
  yield put(resolveEditorPositionIndex())
  yield put(openInlineEditor())
}

export function* updateAndReorderFilesSaga(action: PayloadAction<IUpdateAndReorderFiles>) {
  yield put(setLeadImage({ folderId: action.payload.folderId, leadImageId: action.payload.leadImageId }))
  yield put(reorderFiles({ folderId: action.payload.folderId, id: action.payload.leadImageId }))
}

export default function* () {
  yield takeLatest(resolveEditorPositionIndex.type, resolveEditorPositionSaga)
  yield takeLatest(closeInlineEditor.type, closeInlineEditorSaga)
  yield takeLatest(resolveEditorPositionAndOpen.type, resolveEditorPositionAndOpenSaga)
  yield takeLatest(updateAndReorderFiles.type, updateAndReorderFilesSaga)
}
