import request from 'superagent'

export interface ICloudinaryConfig {
  deleteRawUrl: string
  downloadRawUrl: string
  uploadUrl: string
  deleteUrl: string
  uploadRawUrl: string
  downloadUrl: string
  apiKey: string
}

export const getCloudinaryConfig = (): Promise<ICloudinaryConfig> => (
  request
    .get('/assets/api/uploads/config/cloudinary')
    .send()
    .then((res) => res.body)
    .catch(() => {
      throw new Error('Failed to get Cloudinary config')
    })
)
