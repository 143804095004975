import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import { breakpoints, colours } from 'bdx-af-ui/theme'

import Placeholder from './ThumbnailPlaceholder'
import { toggleFolderSelection, toggleMultipleSelection, IFolder } from '../../../store/ducks/media'
import Image from './Image'
import IndexNumber from './IndexNumber'
import ActionsOverlay, { Container as ActionsOverlayContainer } from './ActionsOverlay'
import GroupIndicatorBackground from './GroupIndicatorBackground'
import { IRootState } from '../../../store/rootReducer'
import { IRequestStatus, getRequestStatus } from '../../../store/ducks/apiRequestsStatuses'
import StatusOverlay from './StatusOverlay'

export const THUMBNAIL_WIDTH = 150

interface IThumbnailProps {
  folder: IFolder
  index: number | undefined
  requestStatus: IRequestStatus
  toggleFolderSelection: typeof toggleFolderSelection
  toggleMultipleSelection: typeof toggleMultipleSelection
}
interface IContainerProps {
  isSelected?: boolean
  isMultiple?: boolean
  isPlaceholder?: boolean
  isExpanded?: boolean
  isPending?: boolean
}

const Container = styled.div<IContainerProps>`
  position: relative;
  width: ${THUMBNAIL_WIDTH}px;
  height: 150px;
  cursor: ${({ isPlaceholder }) => isPlaceholder ? '' : 'pointer'};
  border: ${({ isSelected }) => isSelected ? '4px solid rgba(255, 156, 0, 1)' : 'none'};
  z-index: 10;
  &:hover {
    & > ${ActionsOverlayContainer} {
      display: ${({ isExpanded, isPending }) => isExpanded || isPending ? '' : 'block'};
    }
  }
  @media ${breakpoints.down(breakpoints.breakpoints.sm)} {
    & > ${ActionsOverlayContainer} {
      display: block;
    }
  }
`
const InlineEditorConnector = styled.div<IContainerProps>`
  background-color: ${colours.background.dark};
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 20px;
  bottom: -20px;
  left: ${({ isSelected }) => isSelected ? '-4px' : '0px'};
  width: ${({ isSelected }) => isSelected ? 'calc(100% + 8px)' : '100%'};
  display: ${({ isExpanded }) => isExpanded ? 'block' : 'none'};
`

export const Thumbnail: FunctionComponent<IThumbnailProps> = ({ folder, index, toggleFolderSelection, toggleMultipleSelection, requestStatus }) => {
  const { files, id, isPlaceholder, isExpanded } = folder
  const { isPending } = requestStatus
  const isMultiple = folder.files.length > 1
  const isSelected = index !== undefined
  const indexNumber = index + 1

  const handleSelection = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    if (isPlaceholder || isPending) {
      return
    }
    e.shiftKey
      ? toggleMultipleSelection({ folder })
      : toggleFolderSelection({ folderId: folder.id })
  }

  return (
    <Container
      isSelected={isSelected}
      isMultiple={isMultiple}
      isPlaceholder={isPlaceholder}
      isExpanded={isExpanded}
      isPending={isPending}
      data-testid='thumbnail-container'
    >
      {isPlaceholder
        ? <Placeholder />
        : <>
          <Image
            url={`${files[0].baseUrl}/?maxHeight=600&maxWidth=600&t=${files[0].updatedDate}`}
            isMultiple={isMultiple}
            handleClick={handleSelection}
          />
          <ActionsOverlay folder={folder} />
          {isSelected && indexNumber && <IndexNumber index={indexNumber} folderId={id} />}
          {isMultiple && <GroupIndicatorBackground filesLength={files.length} />}
          <InlineEditorConnector isExpanded={isExpanded} isSelected={isSelected} />
        </>
      }
      <StatusOverlay folder={folder} requestStatus={requestStatus} />
    </Container>
  )
}

const mapStateToProps = (state: IRootState, ownProps: Partial<IThumbnailProps>) => ({
  requestStatus: getRequestStatus(ownProps.folder.id)(state)
})
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  toggleFolderSelection,
  toggleMultipleSelection
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Thumbnail)
