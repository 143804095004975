import { StringUtils } from 'bdx-af-ui/utils'

import { IFolder, IExtendedFile, ITransformPlaceholder } from './index'
import { IMedia } from '../../../api/media'

export const getBlankMedia = (overrides?: Partial<IMedia>): IMedia => ({
  type: '',
  createdDate: '',
  updatedDate: '',
  title: '',
  description: '',
  extension: '',
  id: '',
  groupId: '',
  baseUrl: '',
  ...overrides
})
export const getBlankFolder = (overrides?: Partial<IFolder>): IFolder => ({
  id: StringUtils.getUUID(),
  isPlaceholder: false,
  hasError: false,
  errorText: '',
  isMultiselected: false,
  files: [],
  isExpanded: false,
  ...overrides
})

export const generateUploadPlaceholders = (folders: Array<IFolder>, files: Array<IExtendedFile>) => ([
  ...files
    .map(file => getBlankFolder({
      isPlaceholder: true,
      id: file.folderId,
      hasError: !file.uploadStatus.shouldUpload,
      errorText: file.uploadStatus.reason
    })),
  ...folders
])

export const resolvePlaceholderTransform = (folder: IFolder, data: ITransformPlaceholder, fileBaseUrl: string): IFolder => ({
  ...folder,
  isPlaceholder: !data.success,
  hasError: !data.success,
  errorText: data.success ? '' : data.error || folder.errorText,
  files: data.success
    ? [getBlankMedia({
      baseUrl: `${fileBaseUrl}/v2/files/${data.fileId}/image`,
      id: data.fileId,
      updatedDate: Date.now().toString(),
      type: data.type,
      title: data.title,
      position: 0
    })]
    : []
})
