import React from 'react'
import styled from 'styled-components'
import { Carousel, ImageLoader, GridContainer, GridItem } from 'bdx-af-ui/core'

import { IAssetFile } from '../../../store/ducks/assets'
import RemoveImageButton from './RemoveImageButton'
import Image from './Image'
import { isFileAnImage } from '../../../utils/fileUpload'

interface IAllImagesProps {
  files: Array<IAssetFile>
  isExpanded?: boolean
  assetUuid: string
}

const Container = styled(GridContainer)`
  margin-top: 0.6rem;
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`

export const AllImages: React.FunctionComponent<IAllImagesProps> = ({ assetUuid, files, isExpanded }) => {
  const imageSize = isExpanded ? '150px' : '55px'
  return (
    files.length > 0 &&
    <Container justify='flex-start' alignContent='center'>
      {files.length <= 4 && <GridItem xs={1} />}
      <Carousel
        visibleItems={files.length < 4 ? files.length : 4}
        hideChevrons={files.length <= 4}
        scrollPerChevronClick={1}
        activeChevronColor='gray'
        unactiveChevronColor='#d4d4d4'
      >
        {files
          .slice()
          .sort((a, b) => a.position - b.position)
          .map(file => (
            <React.Fragment key={file.id}>
              <ItemContainer>
                <ImageLoader
                  positioning={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                  }}
                  image={
                    <Image
                      src={`${file.baseUrl}?maxHeight=600&maxWidth=600`}
                      fileId={file.id}
                      assetUuid={assetUuid}
                      size={imageSize}
                      isLead={file.position === 1}
                      isExpanded={isExpanded}
                      isImageType={isFileAnImage(file)}
                    />
                  }
                />
              </ItemContainer>
              <RemoveImageButton
                assetUuid={assetUuid}
                file={file}
              />
            </React.Fragment>
          ))}
      </Carousel>
    </Container>
  )
}

export default AllImages
