import React from 'react'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import { Button } from 'bdx-af-ui/core'
import { StringUtils } from 'bdx-af-ui/utils'

import AssignByIndexNumberConfirmationModal from './AssignByIndexNumberConfirmationModal'
import {
  assigningFoldersByIndexNumberRequest,
  getSelectedFolders,
  IFolder,
  getAssignmentOutcome,
  IAssignmentOutcome
} from '../../../store/ducks/media'
import { getMode, getSelectedObject } from '../../../store/ducks/mode'
import { IAssetGroup } from '../../../api/assetGroups'
import { IDeal } from '../../../store/ducks/deals'

interface IAssignByIndexNumberButton {
  foldersToAssign: Array<IFolder>
  selected: IAssetGroup | IDeal
  mode: string
  assigningFoldersByIndexNumberRequest: typeof assigningFoldersByIndexNumberRequest
  assignmentOutcome: IAssignmentOutcome
}

export const AssignByIndexNumberButton: React.FunctionComponent<IAssignByIndexNumberButton> = ({ foldersToAssign, selected, mode, assigningFoldersByIndexNumberRequest, assignmentOutcome }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [disabledReason, setDisabledReason] = React.useState(null)

  React.useEffect(() => {
    if (selected) {
      setDisabledReason(null)
    } else {
      setDisabledReason('No ' + StringUtils.entities.decode(mode) + '/folders are selected')
    }
  }, [foldersToAssign, selected, mode])
  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setIsModalOpen(true)
  }
  const handleFolderRemove = () => {
    assigningFoldersByIndexNumberRequest({ folders: foldersToAssign })
    setIsModalOpen(false)
  }
  const handleModalClose = () => {
    setIsModalOpen(false)
  }
  const isDisabled = foldersToAssign.length === 0 || !selected
  return (
    <>
      <Button onClick={handleButtonClick} variant='contained' isDisabled={isDisabled} disabled={isDisabled} title={disabledReason}>
        ASSIGN SELECTED ITEMS ({foldersToAssign.length})
      </Button>
      <AssignByIndexNumberConfirmationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleFolderRemove}
        selected={selected}
        mode={mode}
        updatedAssets={assignmentOutcome.updateAssetCount}
        newAssets={assignmentOutcome.newAssetsCount}
      />
    </>
  )
}

const mapStateToProps = (state: any) => ({
  foldersToAssign: getSelectedFolders(state),
  selected: getSelectedObject(state),
  mode: getMode(state),
  assignmentOutcome: getAssignmentOutcome(state)
})
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  assigningFoldersByIndexNumberRequest
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AssignByIndexNumberButton)
