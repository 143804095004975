import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Modal, Paper } from 'bdx-af-ui/core'
import { Chevron, Close } from 'bdx-af-ui/icons'
import { colours } from 'bdx-af-ui/theme'

import TagsModal from '../Tags/TagsModal'
import { ITags } from '../../../store/ducks/media'
import { IRootState } from '../../../store/rootReducer'
import { getMode, getSelectedObjectName, MediaLibraryMode } from '../../../store/ducks/mode'
import { Options } from './Options'

interface IUploadModal {
  catalogueName: string
  mode: MediaLibraryMode
  fileLength: number
  isOpen: boolean
  onConfirm: (filename: boolean, tags: ITags) => void
  onClose: () => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 4rem;
  position: relative;
  text-align: center;
`
const CloseButton = styled.button`
  position: absolute;
  right: 1rem;
  top: 1rem;
  border: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
`

const BackButtonContainer = styled.div`
  position: absolute;
  left: 1rem;
  top: 1rem;
  border: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
`

export const UploadModal: FunctionComponent<IUploadModal> = ({ catalogueName, mode, fileLength, isOpen, onConfirm, onClose }) => {
  const [modalPath, setModalPath] = useState('default')
  const handleConfirm = (filename: boolean, catalogueTag: ITags = { catalogueTag: '' }) => {
    setModalPath('default')
    onConfirm(filename, catalogueTag)
  }
  const handleClose = () => {
    setModalPath('default')
    onClose()
  }
  const handleSelection = (path: string) => {
    setModalPath(path)
  }
  const setDefaultModalPath = () => {
    setModalPath('default')
  }
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Paper>
        <Container>
          <CloseButton data-testid='close-button' onClick={handleClose}>
            <Close />
          </CloseButton>
          {modalPath !== 'default' &&
            <BackButtonContainer
              data-testid='back-button'
              onClick={setDefaultModalPath}
            >
              <Chevron
                direction={'left'}
                fillColor={colours.icons.main}
              />
            </BackButtonContainer>
          }
          {modalPath === 'tag'
            ? <TagsModal onClose={handleClose} onConfirm={handleConfirm} />
            : <Options
              mode={mode}
              catalogueName={catalogueName}
              fileLength={fileLength}
              handleClick={handleSelection}
              onConfirm={handleConfirm}
            />
          }
        </Container>
      </Paper>
    </Modal>
  )
}

const mapStateToProps = (state: IRootState) => ({
  catalogueName: getSelectedObjectName(state),
  mode: getMode(state)
})

export default connect(mapStateToProps)(UploadModal)
