import {
  ActionCreatorWithPayload,
  ActionCreatorWithoutPayload,
  createAction,
  createReducer,
  PayloadAction
} from '@reduxjs/toolkit'

export interface ISelectionItem {
  value: number
  label: string
}

export interface ICurrentMode {
  mode: string
  label: string
  selectedItemId: string
  selectionItems: ISelectionItem[]
}

export enum MediaLibraryMode {
  CATALOGUE = 'catalogue',
  DEAL = 'deal'
}

export interface IModeLabels {
  [index: string]: string
}

export const modeLabels: IModeLabels = {
  catalogue: 'Catalogue',
  deal: 'Deal'
}

export interface ISetMode {
  mode: string
}

export interface ISetLabel {
  label: string
}

export interface ISetSelectionItems {
  items: any[]
}

interface ISetSelectedItemId {
  selectedItemId: number
}

export enum ModeInitialStateParam {
  INITIAL_DEAL_ID = 'initialDealId',
  MODE = 'mode'
}

export const setMode: ActionCreatorWithPayload<ISetMode> = createAction('setMode')
export const initMode: ActionCreatorWithPayload<ISetMode> = createAction('initMode')
export const initSelector: ActionCreatorWithoutPayload = createAction('initSelector')
export const setSelectedItemId: ActionCreatorWithPayload<ISetSelectedItemId> = createAction('setSelectedItemId')
export const setLabel: ActionCreatorWithPayload<ISetLabel> = createAction('setLabel')
export const setSelectionItems: ActionCreatorWithPayload<ISetSelectionItems> = createAction('setSelectionItems')

const initialState: ICurrentMode = {
  mode: MediaLibraryMode.CATALOGUE,
  label: modeLabels.catalogue,
  selectedItemId: null,
  selectionItems: []
}

const reducer = createReducer(initialState, {
  [setMode.type]: (state, { payload: { mode } }: PayloadAction<ICurrentMode>) => {
    state.mode = mode
  },
  [setLabel.type]: (state, { payload: { label } }: PayloadAction<ISetLabel>) => {
    state.label = label
  },
  [setSelectedItemId.type]: (state, { payload: { selectedItemId } }: PayloadAction<ICurrentMode>) => {
    state.selectedItemId = selectedItemId
  },
  [setSelectionItems.type]: (state, { payload: { items } }: PayloadAction<ISetSelectionItems>) => {
    state.selectionItems = items
  }
})

export * from './selectors'
export default reducer
