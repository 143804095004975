import { put, takeLatest, call, select } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'

import { initSelector, getMode, setMode, ISetMode, setLabel, initMode, MediaLibraryMode, modeLabels, setSelectedItemId, setSelectionItems } from './'
import { getSelectedAssetGroupId, getAssetGroups } from '../assetGroups'
import { fetchAssetGroupsSaga } from '../assetGroups/sagas'
import { getDeals } from '../deals'
import { fetchDealsSaga } from '../deals/sagas'

export function* initSelectorSaga() {
  let selectedItemId: number
  let items: any[]
  const mode = yield select(getMode)
  if (mode === MediaLibraryMode.CATALOGUE) {
    selectedItemId = yield select(getSelectedAssetGroupId)
    yield call(fetchAssetGroupsSaga)
    items = yield select(getAssetGroups)
  } else if (mode === MediaLibraryMode.DEAL) {
    const params = new URLSearchParams(document.location.search.substring(1))
    selectedItemId = Number(params.get('initialDealId'))
    yield call(fetchDealsSaga)
    items = yield select(getDeals)
  }
  yield put(setSelectionItems({ items: items.map(item => ({ label: item.name, value: item.id })) }))
  yield put(setSelectedItemId({ selectedItemId }))
}

export function* initModeSaga({ payload: { mode } }: PayloadAction<ISetMode>) {
  yield put(setMode({ mode }))
  yield put(setLabel({ label: modeLabels[mode] }))
}

export default function* () {
  yield takeLatest(initSelector.type, initSelectorSaga)
  yield takeLatest(initMode.type, initModeSaga)
}
