import request from 'superagent'

const V2_URL = 'assets/api/v2'

export class AssignFileRequest {
  mode: string
  target: number
  existingAssets: Array<IAssignFile> = []
  newAssets: Array<INewAssetAssignment> = []

  hasItems(): boolean {
    return this.existingAssets.length > 0 || this.newAssets.length > 0
  }
}

export interface IAssignFile {
  assetId: string
  fileId: string
  position: number
  folderId?: string
}

export interface INewAssetAssignment {
  folderId: string
  position?: number
  items: Array<INewAssetAssignmentElement>
}

export interface INewAssetAssignmentElement {
  fileId: string
  position: number
}

export interface IAssignmentStatus {
  FileId: string
  AssetId: string
  Status: string
  Reason?: string
}

export interface IAssignFilesToAssetsResponse {
  successful: boolean
  unsuccessful: boolean
  listOfSuccessful: Array<IAssignmentStatus>
  listOfUnsuccessful: Array<IAssignmentStatus>
}

export interface IGetFilesAssignedToAssetResponse {
  PartitionKey: string
  SortKey: string
  Position: number
  CompanyId: string
  CloudinaryPublicId: string
  CloudinaryVersion: string
  AssetId: string
  MimeType: string
  Title: string
}

interface IValidateAssignByFilename {
  filename: string
}

export interface IValidatedFileName {
  assetUuid: string
  filename: string
  position?: number
  status: string
  reason: string
}

export const assignFilesToAssets = (assignments: AssignFileRequest): Promise<IAssignFilesToAssetsResponse> => (
  request
    .post(`/${V2_URL}/assignments`)
    .send(assignments)
    .then((res) => res.body)
    .catch(() => {
      throw new Error('Failed to assign files')
    })
)

export const validateAssignByFilename = (selectedId: string, filenames: Array<IValidateAssignByFilename>): Promise<Array<IValidatedFileName>> => (
  request
    .post(`/${V2_URL}/assignments/match`)
    .send({ selectedId, filenames })
    .then((res) => res.body)
    .catch(() => {
      throw new Error('Failed to validate filename')
    })
)

export const getFilesAssignedToAsset = (assetUUID: string): Promise<Array<IGetFilesAssignedToAssetResponse>> => (
  request
    .get(`/${V2_URL}/assignments?assetId=${assetUUID}`)
    .then((res) => res.body)
    .catch(() => {
      throw new Error('Failed to get assigned files')
    })
)

export const removeFileFromAsset = (fileId: string, assetUUID: string) => (
  request
    .delete(`/${V2_URL}/assignments?fileId=${fileId}&assetId=${assetUUID}`)
    .then((res) => res.body)
    .catch(() => {
      throw new Error('Failed to remove file from asset')
    })
)

export const updateLeadImageOnAsset = (assetId: string, fileId: string) => (
  request
    .post(`/${V2_URL}/assignments/${assetId}/lead`)
    .send({ fileId })
    .then((res) => res.body)
    .catch(() => {
      throw new Error('Failed to set lead image on asset')
    })
)
