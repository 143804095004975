import React, { FunctionComponent, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import debounce from 'lodash.debounce'
import { GridContainer, GridItem, Typography } from 'bdx-af-ui/core'
import { Spinner } from 'bdx-af-ui/icons'

import { IRootState } from '../../store/rootReducer'
import { getFolders, getIndexedItems, fetchMedia, IFolder, IIndexedItems } from '../../store/ducks/media'
import { setMediaContainerWidth, getEditorPositionIndex, resolveEditorPositionIndex } from '../../store/ducks/inlineEditor'
import { getRequestStatus, IRequestStatus } from '../../store/ducks/apiRequestsStatuses'
import InlineEditor from './InlineEditor/Container'
import Thumbnail from './Thumbnail/Thumbnail'

interface IListProps {
  folders: Array<IFolder>
  indexedItems: IIndexedItems
  requestStatus: IRequestStatus
  inlineEditorIndex: number
  setMediaContainerWidth: typeof setMediaContainerWidth
  resolveEditorPositionIndex: typeof resolveEditorPositionIndex
}

const EmptyText = styled(Typography)`
  padding: 1rem;
`
const Container = styled(GridContainer)`
  overflow: auto;
`

export const List: FunctionComponent<IListProps> = ({
  folders, indexedItems, requestStatus, setMediaContainerWidth, inlineEditorIndex, resolveEditorPositionIndex
}) => {
  const containerRef = React.useRef<HTMLDivElement>()
  const handleResize = debounce(() => {
    setMediaContainerWidth({ width: containerRef.current.offsetWidth })
    resolveEditorPositionIndex()
  }, 500)

  useEffect(() => {
    setMediaContainerWidth({ width: containerRef.current.offsetWidth })
    resolveEditorPositionIndex()
    window.addEventListener('resize', () => { handleResize() }, false)
    return window.removeEventListener('resize', () => { handleResize() }, false)
  }, [containerRef.current])

  return (
    <Container spacing={1} ref={containerRef}>
      {
        requestStatus.isPending
          ? <Spinner />
          : folders.length > 0
            ? folders.map((folder, index) => (
              <Fragment key={`media_item_${folder.id}`}>
                <GridItem xl='auto'>
                  <Thumbnail
                    folder={folder}
                    index={indexedItems[folder.id]}
                  />
                </GridItem>
                {index === inlineEditorIndex &&
                  <InlineEditor key='inline_editor' />
                }
              </Fragment>
            ))
            : <GridItem><EmptyText variant='h1' color='textSecondary' align='center'>EMPTY</EmptyText></GridItem>
      }
    </Container>
  )
}

const mapStateToProps = (state: IRootState) => ({
  folders: getFolders(state),
  indexedItems: getIndexedItems(state),
  inlineEditorIndex: getEditorPositionIndex(state),
  requestStatus: getRequestStatus(fetchMedia.type)(state)
})
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  setMediaContainerWidth,
  resolveEditorPositionIndex
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(List)
