import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  createAction,
  createReducer,
  PayloadAction
} from '@reduxjs/toolkit'

export interface IDeal {
  id: number
  name: string
  uuid: string
}

export interface ISetDeals {
  deals: Array<IDeal>
}

export interface ISetSelectedDealId {
  id: string
}

export interface IDealState {
  deals: Array<IDeal>
  selectedDealId: string
}

export const fetchDeals: ActionCreatorWithoutPayload = createAction('fetchDeals')
export const assignSelectedDealIdFromUrl: ActionCreatorWithoutPayload = createAction('assignSelectedDealIdFromUrl')
export const setSelectedDealId: ActionCreatorWithPayload<ISetSelectedDealId> = createAction('setSelectedDealId')
export const setDeals: ActionCreatorWithPayload<ISetDeals> = createAction('setDeals')

const initialState: IDealState = {
  deals: [],
  selectedDealId: ''
}

const reducer = createReducer(initialState, {
  [setSelectedDealId.type]: (state, { payload: { id } }: PayloadAction<ISetSelectedDealId>) => {
    state.selectedDealId = id
  },
  [setDeals.type]: (state, { payload: { deals } }: PayloadAction<ISetDeals>) => {
    state.deals = deals
  }
})

export * from './selectors'
export default reducer
