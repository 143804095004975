import React from 'react'
import styled from 'styled-components'
import { GridItem, ImageLoader } from 'bdx-af-ui/core'
import { ExternalLink } from 'bdx-af-ui/icons'
import { breakpoints } from 'bdx-af-ui/theme'

import { IMedia } from '../../../api/media'

interface IMainImage {
  selectedFile: IMedia
}

const MainImageContainer = styled(GridItem)`
  background-color: rgba(235, 234, 234, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  overflow: hidden;
  position: relative;
  @media ${breakpoints.down(breakpoints.breakpoints.md)} {
    margin-top: 2rem;
  }
`
const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`

const FullSizeImageIcon = styled.a`
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`

export const MainImage: React.FunctionComponent<IMainImage> = ({ selectedFile }) => {
  const imageUrl = `${selectedFile.baseUrl}/?t=${selectedFile.updatedDate}`
  const isImage = selectedFile.type.includes('image')
  return (
    <MainImageContainer xs={12} sm={7}>
      {isImage &&
        <FullSizeImageIcon
          href={imageUrl}
          target='_blank'
          rel='noopener noreferrer'
          title='Show fullsize image'
          data-testid='full-size-image-link'
        >
          <ExternalLink />
        </FullSizeImageIcon>
      }
      <ImageLoader
        image={<Image
          src={imageUrl}
          draggable={false}
        />}
      />
    </MainImageContainer>
  )
}

export default MainImage
