import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { ContentLoader, ImageLoader, Typography } from 'bdx-af-ui/core'

import { IAssetFile } from '../../../store/ducks/assets'
import { IRootState } from '../../../store/rootReducer'
import { getRequestStatus, IRequestStatus } from '../../../store/ducks/apiRequestsStatuses'

interface IMainImageProps {
  files: Array<IAssetFile>
  uuid: string
  requestStatus?: IRequestStatus
}
interface IStyledImageProps {
  isMultiple?: boolean
  isVisible?: boolean
}
interface IGroupIndicatorBackgroundProps {
  filesLength: number
}

const MainContainer = styled.div`
  width: 60px;
  height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`
const GroupIndicatorText = styled(Typography)`
  position: absolute;
  right: 0.2rem;
  bottom: 0.1rem;
`
const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  z-index: 0;
  width: 90%;
  height: 90%;
  left: 10%;
  top: 10%;
  background-color: rgba(236, 236, 236, 1);
`
const StyledImage = styled.img<IStyledImageProps>`
  width: ${({ isMultiple }) => isMultiple ? '80%' : '100%'};
  height: ${({ isMultiple }) => isMultiple ? '80%' : '100%'};
  border-right: ${({ isMultiple }) => isMultiple ? 'solid 1px white' : ''};
  border-bottom: ${({ isMultiple }) => isMultiple ? 'solid 1px white' : ''};
  background-color: ${({ isMultiple }) => isMultiple ? 'rgba(236, 236, 236, 1)' : ''};
  object-fit: contain;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
`
const NoImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
`

const GroupIndicatorBackground: React.FunctionComponent<IGroupIndicatorBackgroundProps> = ({ filesLength }) => (
  <BackgroundContainer>
    <GroupIndicatorText variant='caption'>
      + {filesLength - 1} more
    </GroupIndicatorText>
  </BackgroundContainer>
)

const MainImage: React.FunctionComponent<IMainImageProps> = ({ files, requestStatus }) => (
  <MainContainer>
    {files.length > 1 && <GroupIndicatorBackground filesLength={files.length} />}
    {
      files.length === 0
        ? requestStatus.isPending
          ? (
            <ContentLoader isVisible={true} />
          )
          : (
            <NoImage>
              <Typography variant='caption' align='center'>
                No images assigned.
              </Typography>
            </NoImage>
          )
        : (
          <ImageLoader
            image={<StyledImage
              src={files[0] && `${files[0].baseUrl}?maxHeight=300&maxWidth=300`}
              isMultiple={files.length > 1}
              draggable={false}
              data-testid='img-selector'
            />}
          />
        )
    }
  </MainContainer>
)

const mapStateToProps = (state: IRootState, props: IMainImageProps) => ({
  requestStatus: getRequestStatus(props.uuid)(state)
})

export default connect(mapStateToProps)(MainImage)
