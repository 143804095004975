import { combineReducers } from '@reduxjs/toolkit'

import mediaReducer, { IMediaState } from './ducks/media'
import assetGroupsReducer, { IAssetGroupsState } from './ducks/assetGroups'
import inlineEditorReducer, { IInlineEditorState } from './ducks/inlineEditor'
import assetsReducer, { IAssetsState } from './ducks/assets'
import apiRequestsReducer, { IApiRequestsStatuses } from './ducks/apiRequestsStatuses'
import configsReducer, { IConfigsState } from './ducks/configs'
import modeReducer, { ICurrentMode } from './ducks/mode'
import dealReducer, { IDealState } from './ducks/deals'
import KEYS from './storeKeys'

export interface IRootState {
  [KEYS.MEDIA]: IMediaState
  [KEYS.ASSET_GROUPS]: IAssetGroupsState
  [KEYS.INLINE_EDITOR]: IInlineEditorState
  [KEYS.ASSETS]: IAssetsState
  [KEYS.API_REQUESTS_STATUSES]: IApiRequestsStatuses
  [KEYS.CONFIGS]: IConfigsState
  [KEYS.MODE]: ICurrentMode
  [KEYS.DEALS]: IDealState
}

const rootReducer = combineReducers<IRootState>({
  [KEYS.MEDIA]: mediaReducer,
  [KEYS.ASSET_GROUPS]: assetGroupsReducer,
  [KEYS.INLINE_EDITOR]: inlineEditorReducer,
  [KEYS.ASSETS]: assetsReducer,
  [KEYS.API_REQUESTS_STATUSES]: apiRequestsReducer,
  [KEYS.CONFIGS]: configsReducer,
  [KEYS.MODE]: modeReducer,
  [KEYS.DEALS]: dealReducer
})

export default rootReducer
