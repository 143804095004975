import { put, takeLatest, takeEvery, call, select, delay } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'

import { getAssets, IGetAssetsResponse } from '../../../api/assets'
import { getFilesAssignedToAsset, IGetFilesAssignedToAssetResponse, removeFileFromAsset, updateLeadImageOnAsset } from '../../../api/assignments'
import {
  fetchAssets,
  setAssets,
  clearAssets,
  editAsset,
  IAsset,
  IAssetFile,
  removeFileFromAssetRequest,
  IRemoveFileFromAsset,
  getAssetByUuid,
  ISetLeadImageOnAsset,
  setLeadImageOnAsset
} from './index'
import { handleAsyncCall } from '../apiRequestsStatuses/sagas'
import { setReqeustPending } from '../apiRequestsStatuses'
import { getFileBaseUrl, addFolder } from '../media'
import { getMode, getSelectedItemId } from '../mode'

// REMOVE ME !
// TODO create endpoint for fetching global configs and keep them in store
function* waitForFileBaseurl() {
  let fileBaseUrl = ''
  do {
    fileBaseUrl = yield select(getFileBaseUrl)
    yield delay(100)
  } while (!fileBaseUrl)
  return fileBaseUrl
}

export const createFileObject = (file: IGetFilesAssignedToAssetResponse, fileBaseUrl: string) => ({
  id: file.PartitionKey,
  baseUrl: `${fileBaseUrl}/v2/files/${file.PartitionKey}/image`,
  type: file.MimeType,
  title: file.Title,
  groupId: file.CompanyId,
  position: file.Position
})

function* fetchAndSetFilesAssignedToAsset(assets: Array<IAsset>): any {
  for (const asset of assets) {
    yield put(setReqeustPending({ key: asset.uuid, pending: true }))
  }
  const fileBaseUrl: string = yield call(waitForFileBaseurl)
  for (const asset of assets) {
    const getFilesResponse: Array<IGetFilesAssignedToAssetResponse> = yield handleAsyncCall(asset.uuid, getFilesAssignedToAsset, asset.uuid)
    const files = getFilesResponse.map(file => createFileObject(file, fileBaseUrl))
    yield put(editAsset({
      asset: { uuid: asset.uuid, files }
    }))
  }
}

export function* fetchAssetsSaga() {
  const mode = yield select(getMode)
  const assetOrderProperty = (mode === 'catalogue') ? 'assetOrderIndex' : 'assetDealOrderIndex'
  const selectedId = yield select(getSelectedItemId)
  const getAssetsResponse: IGetAssetsResponse = yield handleAsyncCall(fetchAssets.type, getAssets, mode, selectedId)
  const assetsWithoutFiles = getAssetsResponse.content.map(asset => ({
    id: asset.id,
    uuid: asset.uuid,
    title: asset.title,
    description: asset.description,
    assetOrderIndex: asset.assetOrderIndex,
    assetDealOrderIndex: asset.assetDealOrderIndex,
    assetStatus: asset.assetStatus,
    files: []
  }))
  assetsWithoutFiles.sort((a, b) => (a[assetOrderProperty] <= b[assetOrderProperty] ? -1 : 1))
  yield put(setAssets({ assets: assetsWithoutFiles }))
  yield call(fetchAndSetFilesAssignedToAsset, assetsWithoutFiles)
}

export function* clearAssetsSaga() {
  yield put(setAssets({ assets: [] }))
}

export function* removeImageFromAssetSaga(action: PayloadAction<IRemoveFileFromAsset>) {
  let removedFile
  const currentAsset = yield select(getAssetByUuid(action.payload.assetUuid))
  const asset = {
    ...currentAsset,
    files: currentAsset.files.filter((file: IAssetFile) => {
      if (file.id === action.payload.fileId) removedFile = file
      return file.id !== action.payload.fileId
    })
  }
  yield put(editAsset({ asset }))
  yield removeFileFromAsset(action.payload.fileId, action.payload.assetUuid)
  yield put(addFolder({ file: removedFile }))
}

export function* setLeadImageSaga(action: PayloadAction<ISetLeadImageOnAsset>) {
  const { assignments } = yield handleAsyncCall(action.payload.assetUuid, updateLeadImageOnAsset, action.payload.assetUuid, action.payload.fileId)
  const fileBaseUrl: string = yield call(waitForFileBaseurl)
  const files = assignments.map((file: IGetFilesAssignedToAssetResponse) => createFileObject(file, fileBaseUrl))
  yield put(editAsset({
    asset: { uuid: action.payload.assetUuid, files }
  }))
}

export default function* () {
  yield takeLatest(fetchAssets.type, fetchAssetsSaga)
  yield takeLatest(clearAssets.type, clearAssetsSaga)
  yield takeEvery(setLeadImageOnAsset.type, setLeadImageSaga)
  yield takeEvery(removeFileFromAssetRequest.type, removeImageFromAssetSaga)
}
