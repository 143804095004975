import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { IRootState } from '../../../store/rootReducer'
import { getInitialyUploadedCounter, getUploadedCounter } from '../../../store/ducks/media'

interface IUploadCounterProps {
  initialyUploaded: number
  uploaded: number
}

const Counter = styled.p`
  padding: 10px;
`

export const UploadCounter: React.FunctionComponent<IUploadCounterProps> = ({ initialyUploaded, uploaded }) => (
  initialyUploaded === uploaded
    ? null
    : (
      <Counter>
        Images ({uploaded} of {initialyUploaded})
      </Counter>
    )
)

const mapStateToProps = (state: IRootState) => ({
  initialyUploaded: getInitialyUploadedCounter(state),
  uploaded: getUploadedCounter(state)
})

export default connect(mapStateToProps)(UploadCounter)
