import { createSelector } from '@reduxjs/toolkit'

import { IRootState } from '../../rootReducer'
import KEYS from '../../storeKeys'

const configsSelector = (state: IRootState) => state[KEYS.CONFIGS]

export const getCloudinaryConfig = createSelector(
  configsSelector,
  (state) => state.cloudinary
)
export const getIsAppReady = createSelector(
  getCloudinaryConfig,
  (cloudinaryConfig) => Boolean(cloudinaryConfig)
)
export const getContainerOffsets = createSelector(
  configsSelector,
  (state) => state.containerOffsets
)
