import { createAction, createReducer, ActionCreatorWithPayload, ActionCreatorWithoutPayload, PayloadAction } from '@reduxjs/toolkit'
import { IFolder } from '../media'

export interface IInlineEditorState {
  editorPositionIndex: number
  mediaContainerWidth: number
  selectedFileId: string
  isOpen: boolean
}
interface ISetMediaContainerWidth {
  width: number
}
interface ISetEditorPositionIndex {
  index: number
}
interface ISetFileSelected {
  id: string
}
export interface IResolveEditorPositionAndOpen {
  folder: IFolder
}
export interface IUpdateAndReorderFiles {
  folderId: string
  leadImageId: string
}

export const setMediaContainerWidth: ActionCreatorWithPayload<ISetMediaContainerWidth> = createAction('setMediaContainerWidth')
export const resolveEditorPositionIndex: ActionCreatorWithoutPayload = createAction('resolveEditorPositionIndex')
export const setEditorPositionIndex: ActionCreatorWithPayload<ISetEditorPositionIndex> = createAction('SetEditorPositionIndex')
export const setFileSelected: ActionCreatorWithPayload<ISetFileSelected> = createAction('setFileSelected')
export const closeInlineEditor: ActionCreatorWithoutPayload = createAction('closeInlineEditor')
export const openInlineEditor: ActionCreatorWithoutPayload = createAction('openInlineEditor')
export const resolveEditorPositionAndOpen: ActionCreatorWithPayload<IResolveEditorPositionAndOpen> = createAction('resolveEditorPositionAndOpen')
export const updateAndReorderFiles: ActionCreatorWithPayload<IUpdateAndReorderFiles> = createAction('updateAndReorderFiles')

const initialState: IInlineEditorState = {
  editorPositionIndex: 0,
  mediaContainerWidth: 0,
  selectedFileId: '',
  isOpen: false
}

const reducer = createReducer(initialState, {
  [setMediaContainerWidth.type]: (state, { payload: { width } }: PayloadAction<ISetMediaContainerWidth>) => {
    state.mediaContainerWidth = width
  },
  [setEditorPositionIndex.type]: (state, { payload: { index } }: PayloadAction<ISetEditorPositionIndex>) => {
    state.editorPositionIndex = index
  },
  [setFileSelected.type]: (state, { payload: { id } }: PayloadAction<ISetFileSelected>) => {
    state.selectedFileId = id
  },
  [openInlineEditor.type]: (state) => {
    state.isOpen = true
  },
  [closeInlineEditor.type]: (state) => {
    state.isOpen = false
  }
})

export * from './selectors'
export default reducer
