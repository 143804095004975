import { createSelector } from '@reduxjs/toolkit'

import { IRootState } from '../../rootReducer'
import KEYS from '../../storeKeys'
import { getBlankFolder } from './helpers'
import { isFileAnImage, isFileAnDocument } from '../../../utils/fileUpload'
import { getAssets, IAsset } from '../assets'
import { IFolder, IIndexedItems } from './index'
import { IDeal } from '../deals'
import { IAssetGroup } from '../../../api/assetGroups'
import { getSelectedObject, isInDealMode } from '../mode'

const mediaSelector = (state: IRootState) => state[KEYS.MEDIA]

export const getFolders = createSelector(
  mediaSelector,
  (state) => state.folders
)
export const getSelectableFolders = createSelector(
  mediaSelector,
  (state) => state.folders.filter(folder => !folder.isPlaceholder)
)
export const getInitialyUploadedCounter = createSelector(
  mediaSelector,
  (state) => state.initialyUploadedCounter
)
export const getUploadedCounter = createSelector(
  mediaSelector,
  (state) => state.uploadedCounter
)
export const getImages = createSelector(
  getFolders,
  (folders) => folders
    .filter(folder => !folder.isPlaceholder)
    .reduce((acc, folder) => {
      acc.push(...folder.files.filter(file => isFileAnImage(file)))
      return acc
    }, [])
)
export const getDocuments = createSelector(
  getFolders,
  (folders) => folders
    .filter(folder => !folder.isPlaceholder)
    .reduce((acc, folder) => {
      acc.push(...folder.files.filter(file => isFileAnDocument(file)))
      return acc
    }, [])
)
export const getTotalItemsLength = createSelector(
  mediaSelector,
  (state) => state.folders.reduce((acc, folder) => acc + folder.files.length, 0)
)
export const getIndexedItems = createSelector(
  mediaSelector,
  (state) => state.indexedItems
)
export const getSelectedFolders = createSelector(
  getFolders, getIndexedItems,
  (folders, indexedItems) => folders.filter(folder => Object.keys(indexedItems).includes(folder.id))
)
export const getFileBaseUrl = createSelector(
  mediaSelector,
  (state) => state.fileBaseUrl
)
export const getExpandedFolder = createSelector(
  getFolders,
  (folders) => folders.find(folder => folder.isExpanded) || getBlankFolder()
)
export const getIndexNumber = (folderId: string) => createSelector(
  getIndexedItems,
  (indexedItems) => indexedItems[folderId]
)

export interface IAssignmentOutcome {
  updateAssetCount: number
  newAssetsCount: number
}

const combiner = (assets: IAsset[], indexedItems: IIndexedItems, selectedObject: IDeal | IAssetGroup, dealMode: boolean, selectedFolders: IFolder[]): IAssignmentOutcome => {
  let updated = 0
  let created = 0

  for (const folder of selectedFolders) {
    const indexNumber = indexedItems[folder.id] + 1
    const matchingAsset = assets.find(asset => asset.rowNumber === indexNumber)
    if (matchingAsset) {
      updated += 1
    } else {
      created += 1
    }
  }

  return {
    updateAssetCount: updated,
    newAssetsCount: created
  }
}

export const getAssignmentOutcome = createSelector(getAssets, getIndexedItems, getSelectedObject, isInDealMode, getSelectedFolders, combiner)
