import React from 'react'
import styled from 'styled-components'
import { Button, GridContainer, Modal, Paper, Typography } from 'bdx-af-ui/core'
import { Close } from 'bdx-af-ui/icons'
import { StringUtils } from 'bdx-af-ui/utils'

import { IDeal } from '../../../store/ducks/deals'
import { IAssetGroup } from '../../../api/assetGroups'

interface IAssignByIndexNumberConfirmationModal {
  isOpen: boolean
  onConfirm: () => void
  onClose: () => void
  selected: IDeal | IAssetGroup
  mode: string
  updatedAssets: number
  newAssets: number
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 4rem;
  position: relative;
  text-align: center;
`
const CloseButton = styled.button`
  position: absolute;
  right: 1rem;
  top: 1rem;
  border: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
`

export const AssignByIndexNumberConfirmationModal: React.FunctionComponent<IAssignByIndexNumberConfirmationModal> = ({ isOpen, onConfirm, onClose, selected, mode, updatedAssets, newAssets }) => {
  const handleConfirm = () => {
    onConfirm()
  }
  const handleClose = () => {
    onClose()
  }

  const lines = []

  if (updatedAssets) {
    lines.push(`${updatedAssets} asset${updatedAssets > 1 ? 's' : ''} will be updated.`)
  }
  if (newAssets) {
    lines.push(`${newAssets} asset${newAssets > 1 ? 's' : ''} will be created.`)
  }

  const text = lines.join(' ')

  return (
    isOpen && <Modal open={isOpen} onClose={handleClose}>
      <Paper>
        <Container>
          <CloseButton onClick={handleClose}>
            <Close />
          </CloseButton>
          <Typography variant='h2' marginBottom='1rem'>You are about to assign files to {StringUtils.entities.decode(mode)} &quot;{StringUtils.entities.decode(selected.name)}&quot;.</Typography>
          <Typography component='div' variant='body1' marginBottom='1rem'>Files and file groups will be matched based on index number.</Typography>
          <Typography component='div' variant='body1' marginBottom='2rem'>{text}</Typography>
          <GridContainer>
            <Button variant='text' size='large' onClick={handleClose}>Cancel</Button>
            <Button variant='contained' size='large' onClick={handleConfirm} >Assign</Button>
          </GridContainer>
        </Container>
      </Paper>
    </Modal>
  )
}

export default AssignByIndexNumberConfirmationModal
