import { IDeal } from '../store/ducks/deals'
import request from 'superagent'

export interface IGetDealsResponse {
  content: Array<IDeal>
}

const defaultParams = {
  size: 1000,
  status: 'ACTIVE'
}

export const getDeals = (): Promise<IGetDealsResponse> => {
  return request
    .get('/assets/api/deals')
    .query(defaultParams)
    .send()
    .then((res) => res.body)
    .catch(() => {
      throw new Error('Failed to get deals')
    })
}
