import React, { FunctionComponent } from 'react'
import { Button, OptionContainer, Typography } from 'bdx-af-ui/core'
import { FileWriting, Tag } from 'bdx-af-ui/icons'
import { MediaLibraryMode } from '../../../store/ducks/mode'
import { ITags } from '../../../store/ducks/media'
import styled from 'styled-components'

const ThumbnailsWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  display: flex;
`

const UploadButton = styled(Button)`
  margin-top: 0.5rem;
`
const TagContainer = styled.div`
  margin-left: 1rem;
`

interface INavigation {
  mode: MediaLibraryMode
  catalogueName: string
  fileLength: number
  handleClick: (path: string) => void
  onConfirm?: (filename: boolean, tags?: ITags) => void
}

export const Options: FunctionComponent<INavigation> = ({ mode, catalogueName, fileLength, handleClick, onConfirm }) => {
  const assignByLotNumberEnabled = mode !== MediaLibraryMode.CATALOGUE ? true : !catalogueName

  const disabledText = mode === MediaLibraryMode.CATALOGUE ? 'You must have a catalogue selected on the left to use this option'
    : 'This option is not available for Deals'

  return (
    <>
      <Typography variant='h2' marginBottom='1rem'>{`You're about to upload ${fileLength} file(s)`}</Typography>
      <Typography variant='body2' marginBottom='1rem'>How would you like to arrange them?</Typography>
      <ThumbnailsWrapper>
        <div
          data-testid='upload-and-assign'
          onClick={() => {
            if (!assignByLotNumberEnabled) onConfirm(true)
          }}
        >
          <OptionContainer
            text={`Assign files to ${catalogueName} catalogue by Lot Number`}
            disabledText={disabledText}
            disabled={assignByLotNumberEnabled}
            subtext={'(this will initiate upload)'}
            icon={<FileWriting/>}
          />
        </div>
        <TagContainer data-testid='tag-navigation-button' onClick={() => handleClick('tag')}>
          <OptionContainer
            text='Upload & Tag Files'
            icon={<Tag width={'40px'} height={'40px'}/>}
          />
        </TagContainer>
      </ThumbnailsWrapper>
      <Typography>Or</Typography>
      <UploadButton
        data-testid='upload'
        variant='contained'
        onClick={() => onConfirm(false)}
        size='large'
      >
        Upload
      </UploadButton>
    </>
  )
}
