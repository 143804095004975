import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { Bookmark, MinusInCircle } from 'bdx-af-ui/icons'
import { colours } from 'bdx-af-ui/theme'

import { updateAndReorderFiles, setFileSelected } from '../../../store/ducks/inlineEditor'
import { removeFileFromFolderRequest } from '../../../store/ducks/media'

interface IImageProps {
  updateAndReorderFiles: typeof updateAndReorderFiles
  setFileSelected: typeof setFileSelected
  removeFileFromFolderRequest: typeof removeFileFromFolderRequest
  fileId: string
  folderId: string
  src: string
  width: string
  height: string
  isLead: boolean
  isRemoveButtonVisible: boolean
  onLoad?: () => void
  isImageType: boolean
}

const Container = styled.div`
  position: relative;
`
const StyledImage = styled.img`
  cursor: pointer;
  object-fit: contain;
  padding: 0.2rem;
`
const BookmarkIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0.7rem;
  cursor: pointer;
`
const RemoveIconContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Image: FunctionComponent<IImageProps> = ({
  folderId, fileId, src, width = '70px', height = '70px', isLead, updateAndReorderFiles,
  setFileSelected, onLoad, removeFileFromFolderRequest, isRemoveButtonVisible, isImageType
}) => {
  const bookmarkColor = (isLead ? colours.primary.main : colours.text.disabled)
  const bookmarkOutline = (isLead ? colours.text.disabled : colours.primary.main)
  const handleRemoveFromGroup = () => removeFileFromFolderRequest({ fileId, folderId })
  return (
    <Container>
      <StyledImage
        src={src}
        width={width}
        height={height}
        onLoad={onLoad}
        onClick={() => setFileSelected({ id: fileId })}
        draggable={false}
      />
      {
        isImageType &&
        <BookmarkIconContainer
          data-testid='lead-image-icon'
          onClick={() => {
            updateAndReorderFiles({ folderId, leadImageId: fileId })
            setFileSelected({ id: fileId })
          }}
          title={'Set as lead image'}
        >
          <Bookmark
            fillColor={bookmarkColor}
            strokeColor={bookmarkOutline}
          />
        </BookmarkIconContainer>
      }
      {
        isRemoveButtonVisible &&
        <RemoveIconContainer
          onClick={handleRemoveFromGroup}
          data-testid='remove-file-icon'
        >
          <MinusInCircle />
        </RemoveIconContainer>
      }
    </Container>
  )
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  updateAndReorderFiles,
  setFileSelected,
  removeFileFromFolderRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(Image)
