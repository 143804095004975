import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Carousel, GridContainer, ImageLoader } from 'bdx-af-ui/core'
import { breakpoints } from 'bdx-af-ui/theme'

import { IMedia } from '../../../api/media'
import Image from './Image'
import { getMediaContainerWidth } from '../../../store/ducks/inlineEditor'
import { getExpandedFolder } from '../../../store/ducks/media'
import { IRootState } from '../../../store/rootReducer'
import { isFileAnImage } from '../../../utils/fileUpload'

interface IAllImagesProps {
  files: Array<IMedia>
  inlineEditorWidth?: number
  folderId: string
}

const Container = styled(GridContainer)`
  height: 100%;
  @media ${breakpoints.down(breakpoints.breakpoints.md)} {
    margin-top: 1rem;
  }
`

const ImageContainer = styled.div`
  position: relative;
`

export const AllImages: React.FunctionComponent<IAllImagesProps> = ({ folderId, files, inlineEditorWidth }) => {
  const visibleImages = inlineEditorWidth > 950 ? 4 : 3
  const visibleItems = files.length < visibleImages ? files.length : visibleImages
  const hideChevrons = files.length <= visibleImages
  const isRemoveButtonVisible = files.length > 1

  return (
    <Container direction='row' justify='center' alignContent='center' spacing={1}>
      <Carousel
        visibleItems={visibleItems}
        hideChevrons={hideChevrons}
        scrollPerChevronClick={1}
      >
        {files.map((file) => {
          const isLead = file.position === 0
          return (
            <ImageContainer key={file.id}>
              <ImageLoader
                height='70px'
                width='70px'
                image={
                  <Image
                    fileId={file.id}
                    isImageType={isFileAnImage(file)}
                    folderId={folderId}
                    height='70px'
                    width='70px'
                    isLead={isLead}
                    src={`${file.baseUrl}/?maxHeight=800&t=${file.updatedDate}`}
                    isRemoveButtonVisible={isRemoveButtonVisible}
                  />
                }
              />
            </ImageContainer>
          )
        })}
      </Carousel>
    </Container>
  )
}

const mapStateToProps = (state: IRootState) => ({
  files: getExpandedFolder(state).files,
  folderId: getExpandedFolder(state).id,
  inlineEditorWidth: getMediaContainerWidth(state)
})

export default connect(mapStateToProps)(AllImages)
