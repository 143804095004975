import request from 'superagent'
import { UploadUtils } from 'bdx-af-ui/utils'

import { ICloudinaryConfig } from './configs'
import { isFileAnImage } from '../utils/fileUpload'

const V1_URL = 'assets/api'
const V2_URL = 'assets/api/v2'

export interface IMedia {
  type?: string
  createdDate?: string
  updatedDate?: string
  title?: string
  description?: string
  position?: number
  extension?: string
  id?: string
  groupId?: string
  baseUrl?: string
}
export interface IGetMedia {
  fileBaseUrl: string
  files: Array<IMedia>
}
interface IServerMediaModel {
  groupId?: string
  id: string
  mimeType?: string | null
  title?: string
}
export interface IServerUploadResponse {
  action?: string
  message?: string
  model: Array<IServerMediaModel>
  success?: boolean
}
export interface IGetMediaSignatureResponse {
  model: {
    url?: string
    data?: {
      apiKey?: string
      signature?: string
      public_id?: string
      timestamp?: number
      transformation?: string
      image_metadata: boolean
      tags?: Array<string>
    }
  }
  success: boolean
  message?: string
}
export interface IUploadMediaToClodinaryResponse {
  public_id?: string
  version?: number
  signature?: string
  width?: number
  height?: number
  format?: string
  resource_type?: string
  created_at?: string
  tags?: Array<string>
  pages?: number
  bytes?: number
  type?: string
  etag?: string
  placeholder?: boolean
  url?: string
  secure_url?: string
  image_metadata?: {
    JFIFVersion?: number
    ResolutionUnit?: string
    XResolution?: number
    YResolution?: number
    Colorspace?: string
    DPI?: number
  }
  illustration_score?: number
  semi_transparent?: boolean
  grayscale?: boolean
}

export const getMedia = (): Promise<IGetMedia> => (
  request
    .get(`/${V2_URL}/files`)
    .then((res) => res.body)
    .catch(() => {
      throw new Error('Failed to get media')
    })
)

export const uploadMediaToCloudinary = (file: string, fileData: any, responseUrl: string, type: string, config: ICloudinaryConfig): Promise<IUploadMediaToClodinaryResponse> => {
  const data = {
    ...fileData,
    file,
    transformation: 'c_limit,w_2000,h_2000,q_80'
  }
  const url = responseUrl || ((type.includes('image/')) ? config.uploadUrl : config.uploadRawUrl)
  return request
    .post(url)
    .send(data)
    .then(res => res.body)
    .catch(() => {
      throw new Error('Error while uploading to cloudinary.')
    })
}

export const uploadMediaToServer = (data: IUploadMediaToClodinaryResponse, tag: string, assetId: string, createdAt: number): Promise<IServerUploadResponse> => (
  request
    .post(`/${V2_URL}/uploads/confirm`)
    .send({ uploadResponse: data, assetUuid: assetId, createdAt, tag: tag || undefined })
    .then((res) => res.body)
    .catch(() => {
      throw new Error('Failed to upload media to server.')
    })
)

export const getMediaSignature = (file: File): Promise<IGetMediaSignatureResponse> => {
  // this endpoint is inconsistent, to work properly it requires extension in filename for non-image files
  // and no extension in filename for image files
  const isImage = isFileAnImage(file)
  const fileName = UploadUtils.normalizeFilename(file.name, isImage)
  const data = new FormData()
  data.append('imageOnly', String(isImage))
  data.append('content', file.slice(0, 100))
  return request
    .post(`/${V1_URL}/uploads/signature?filename=${encodeURIComponent(fileName)}`)
    .send(data)
    .then(res => res.body)
    .catch(() => {
      throw new Error('Failed to get media signature.')
    })
}

export const deleteMedia = (file: IMedia) => (
  request
    .delete(`/${V2_URL}/files/${file.id}`)
    .then((res) => res.body)
    .catch(() => {
      throw new Error('Failed to delete media.')
    })
)

export const editMedia = (id: string, rotationAngle?: 90 | 270, newFileName?: string) => (
  request
    .put(`/${V2_URL}/files/${id}`)
    .send({ rotationAngle, newFileName })
    .then((res) => res.body)
    .catch(() => {
      throw new Error('Failed to edit media.')
    })
)
