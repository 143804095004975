import React, { FunctionComponent, useState, useEffect } from 'react'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { BaseInput as IndexInput } from 'bdx-af-ui/core'
import { colours } from 'bdx-af-ui/theme'

import { updateIndexFromPosition, toggleFolderSelection } from '../../../store/ducks/media'

const StyledIndexInput = styled(IndexInput)`
  && {
    position: absolute;
    font-size:  0.7rem;
    top: 0.5rem;
    left: 0.5rem;
    background: white;
    color: black;
    padding: 0;
    border-radius: 0.4rem;
    text-align: center;
    border: 1px solid ${colours.common.gray};
    max-width: 3rem;
    input {
      text-align: center;
      width: 100%;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
  }
`

interface IIndexNumberProps {
  updateIndexFromPosition: typeof updateIndexFromPosition
  toggleFolderSelection: typeof toggleFolderSelection
  folderId: string
  index: number | undefined
}

export const IndexNumber: FunctionComponent<IIndexNumberProps> = ({ index, folderId, updateIndexFromPosition, toggleFolderSelection }) => {
  const [stateIndex, setStateIndex] = useState(index)
  useEffect(() => {
    setStateIndex(index)
  }, [index])
  const handleIndexChange = (e: React.ChangeEvent<HTMLInputElement>): void => handleValueChange(Number(e.target.value), true, false)
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => handleValueChange(Number(e.target.value), true, true)
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') handleValueChange(Number(e.currentTarget.value), true, true)
  }
  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => e.currentTarget.select()
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => e.target.select()
  const handleValueChange = (newIndex: number, updateState: boolean, updateIndex: boolean) => {
    if (isNaN(newIndex)) return
    const fullNumberIndex = Math.floor(newIndex)
    if (updateState && updateIndex && fullNumberIndex === 0) {
      toggleFolderSelection({ folderId })
    }
    if (updateState) setStateIndex(fullNumberIndex)
    if (fullNumberIndex < 1 || !fullNumberIndex || fullNumberIndex > 99999) return
    if (updateIndex) updateIndexFromPosition({ newIndex, folderId })
  }
  return (
    <StyledIndexInput
      data-testid='index-number'
      onChange={handleIndexChange}
      onBlur={handleBlur}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      min={1}
      maxLength={5}
      type={'text'}
      value={stateIndex}
      pattern={'[0-9]*'}
    />
  )
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  updateIndexFromPosition,
  toggleFolderSelection
}, dispatch)

export default connect(null, mapDispatchToProps)(IndexNumber)
