import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Chevron } from 'bdx-af-ui/icons'
import { constants, breakpoints } from 'bdx-af-ui/theme'
import { Fade } from 'bdx-af-ui/transitions'
import { bindActionCreators } from 'redux'

import { Selection } from './Selection'
import AssetsList from './AssetsList/AssetsList'
import { connect } from 'react-redux'
import { IRootState } from '../../store/rootReducer'
import { getMode, initSelector } from '../../store/ducks/mode'

interface IContainerProps {
  isExpanded?: boolean
}

interface IDrawerProps {
  mode: string
  initSelector: typeof initSelector
}

const Container = styled.div<IContainerProps>`
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.34);
  height: 100%;
  padding-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  position: relative;
  width: ${({ isExpanded }) => isExpanded ? constants.DRAWER_WIDTH_EXPANDED : constants.DRAWER_WIDTH};
  z-index: 50;
  background-color: white;
  will-change: width;
  transition: width ${({ isExpanded }) => isExpanded ? '0.2s ease-out' : '0.15s ease-in'};
  @media ${breakpoints.down(breakpoints.breakpoints.md)} {
    width: ${({ isExpanded }) => isExpanded ? constants.DRAWER_WIDTH : 0};
    padding: ${({ isExpanded }) => isExpanded ? '0.5rem' : '0'};
  }
`
const ExpandedOverlay = styled.div`
  position: absolute;
  left: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
  width: 100vw;
  height: 100vh;
`
const ChevronContainer = styled.div`
  position: absolute;
  right: -0.5rem;
  top: 0.8rem;
  width: 1.5rem;
  height: 2.5rem;
  display: flex;
  justify-content:center;
  align-items: center;
  box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.34);
  background-color: white;
  cursor: pointer;
  @media ${breakpoints.down(breakpoints.breakpoints.md)} {
    right: -1.3rem;
    top: 1.3rem;
  }
`

const Drawer: FunctionComponent<IDrawerProps> = ({ mode, initSelector }) => {
  useEffect(() => {
    initSelector()
  }, [mode])
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleDrawer = () => {
    setIsExpanded(!isExpanded)
  }
  const chevronDirection = isExpanded ? 'left' : 'right'

  return (
    <Container isExpanded={isExpanded}>
      <Fade isVisible={isExpanded}>
        <ExpandedOverlay onClick={toggleDrawer}/>
      </Fade>
      <ChevronContainer data-testid='drawer-chevron' onClick={toggleDrawer}>
        <Chevron direction={chevronDirection} />
      </ChevronContainer>
      <Selection isExpanded={isExpanded} />
      <AssetsList isExpanded={isExpanded} />
    </Container>
  )
}

const mapStateToProps = (state: IRootState) => ({
  mode: getMode(state)
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  initSelector
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Drawer)
