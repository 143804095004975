import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Autocomplete, BLANK_SUGGESTION, Button, ContentLoader, suggestion, Typography } from 'bdx-af-ui/core'
import { Catalogue, Tag } from 'bdx-af-ui/icons'

import TagThumbnail from './TagThumbnail'
import { IRootState } from '../../../store/rootReducer'
import { fetchAssetGroups, getAssetGroupTags } from '../../../store/ducks/assetGroups'
import { ITags } from '../../../store/ducks/media'
import { getRequestStatus, IRequestStatus } from '../../../store/ducks/apiRequestsStatuses'
import { getMode, isInDealMode, MediaLibraryMode } from '../../../store/ducks/mode'
import { fetchDeals, getDealTags } from '../../../store/ducks/deals'

interface ITagsModal {
  onConfirm: (filename: boolean, tags?: ITags) => void
  onClose: () => void
  suggestions: Array<suggestion>
  requestStatus: IRequestStatus
  mode: MediaLibraryMode
}

const TagIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`
const ThumbnailsWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 0.8rem;
`

const LoaderElement = () => <rect x='0' y='0' rx='0' ry='0' width='190' height='30' />

export const TagsModal: React.FunctionComponent<ITagsModal> = ({ onConfirm, suggestions, requestStatus, mode }) => {
  const [selectedSuggestion, setSelectedSuggestion] = React.useState<suggestion>(BLANK_SUGGESTION)
  const handleTagChange = (suggestion: suggestion) => {
    setSelectedSuggestion(suggestion)
  }
  const handleConfirm = () => {
    onConfirm(false, { catalogueTag: selectedSuggestion.value as string })
    setSelectedSuggestion(BLANK_SUGGESTION)
  }

  return (
    <>
      <Typography variant='h2' marginBottom='1rem'>Do you want to tag files for later use?</Typography>
      <Typography variant='body2' marginBottom='1rem'>Tagging files is a helpful way to pre-arrange big collections of media files.</Typography>
      <ThumbnailsWrapper>
        <TagThumbnail tagText={`TAG BY ${mode.toUpperCase()}`} tagIcon={<Catalogue />}>
          <ContentLoader
            isVisible={requestStatus.isPending}
            loaderSvg={<LoaderElement />}
            width={'190px'}
            height={'30px'}
          >
            <Autocomplete
              suggestions={suggestions}
              selectedSuggestion={selectedSuggestion}
              onSuggestionChange={handleTagChange}
              disabled={suggestions.length === 0}
              placeholder={suggestions.length === 0 ? 'No tags available' : `Choose ${mode}`}
              textFieldProps={{
                startAdornment: <TagIconWrapper><Tag width={'1rem'} height={'1rem'} /></TagIconWrapper>,
                baseInputProps: {
                  width: '165px'
                }
              }}
            />
          </ContentLoader>
        </TagThumbnail>
      </ThumbnailsWrapper>
      <Button variant='contained' onClick={handleConfirm} size='large'>Upload</Button>
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  suggestions: isInDealMode(state) ? getDealTags(state) : getAssetGroupTags(state),
  requestStatus: isInDealMode(state) ? getRequestStatus(fetchDeals.type)(state) : getRequestStatus(fetchAssetGroups.type)(state),
  mode: getMode(state)
})

export default connect(mapStateToProps)(TagsModal)
