import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Autocomplete, BLANK_SUGGESTION, suggestion } from 'bdx-af-ui/core'
import { breakpoints } from 'bdx-af-ui/theme'

import { setSelectedItemId, getModeLabel, ISelectionItem, getSelectionItems, getSelectedOption, isInDealMode } from '../../../store/ducks/mode'
import { fetchAssets, clearAssets } from '../../../store/ducks/assets'
import { IRootState } from '../../../store/rootReducer'
import { Close, MagnifyingGlassClear } from 'bdx-af-ui/icons'
import { setSelectedDealId } from '../../../store/ducks/deals'
import { setSelectedAssetGroupId } from '../../../store/ducks/assetGroups'

interface ISelection {
  items: Array<ISelectionItem>
  setSelectedItemId: typeof setSelectedItemId
  label: string
  selectedOption?: ISelectionItem
  isExpanded: boolean
  fetchAssets: typeof fetchAssets
  clearAssets: typeof clearAssets

  dealMode: boolean
  setSelectedDealId: typeof setSelectedDealId
  setSelectedAssetGroupId: typeof setSelectedAssetGroupId
}

const Container = styled.div<Partial<ISelection>>`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  @media ${breakpoints.down(breakpoints.breakpoints.md)} {
    display: ${({ isExpanded }) => isExpanded ? 'flex' : 'none'}
  }
`

const Label = styled.label`
  margin-right: 5px;
`

const MagnifyingGlassContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
  margin-right: 4px;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  margin-right: 5px;
  cursor: pointer;
`

export const Selection: FunctionComponent<ISelection> = ({
  isExpanded, label, items, selectedOption, setSelectedItemId, fetchAssets, clearAssets, dealMode, setSelectedDealId, setSelectedAssetGroupId
}) => {
  const handleOptionReset = () => {
    setSelectedItemId({ selectedItemId: BLANK_SUGGESTION.value })
    clearAssets()
  }

  const handleOptionSelect = (selectSuggestion: suggestion) => {
    setSelectedItemId({ selectedItemId: selectSuggestion.value })
    if (dealMode) {
      setSelectedDealId({ id: selectSuggestion.value })
    } else {
      setSelectedAssetGroupId({ id: selectSuggestion.value })
    }
    fetchAssets()
  }
  return (
    <Container isExpanded={isExpanded}>
      <Label>{label}</Label>
      <Autocomplete
        suggestions={items.filter(item => item.value !== selectedOption.value)}
        onSuggestionChange={handleOptionSelect}
        selectedSuggestion={selectedOption}
        placeholder={'None selected'}
        textFieldProps={{
          ...(selectedOption !== BLANK_SUGGESTION) && {
            endAdornment: <IconContainer onClick={handleOptionReset} data-testid={'reset-suggestion-selection'}><Close width={'0.8rem'} height={'0.8rem'} /></IconContainer>
          },
          labelProps: {
            display: 'none'
          },
          startAdornment: <MagnifyingGlassContainer><MagnifyingGlassClear fillColor='#000000' fillOpacity={'0.8'} width={'0.8rem'} height={'0.8rem'} viewBox={'0 0 136 136'} /></MagnifyingGlassContainer>
        }}
      />
    </Container>
  )
}

const mapStateToProps = (state: IRootState) => ({
  items: getSelectionItems(state),
  label: getModeLabel(state),
  selectedOption: getSelectedOption(state) || BLANK_SUGGESTION,
  dealMode: isInDealMode(state)
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  setSelectedItemId,
  fetchAssets,
  clearAssets,
  setSelectedDealId,
  setSelectedAssetGroupId
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Selection)
