import { createAction, createReducer, ActionCreatorWithPayload, ActionCreatorWithoutPayload, PayloadAction } from '@reduxjs/toolkit'
import { StringUtils } from 'bdx-af-ui/utils'

import { IMedia } from '../../../api/media'
import { getBlankFolder, generateUploadPlaceholders, resolvePlaceholderTransform } from './helpers'
import { isFileAnImage } from '../../../utils/fileUpload'

export interface IMediaState {
  folders: Array<IFolder>
  indexedItems: IIndexedItems
  initialyUploadedCounter: number
  lastClicked: ILastClicked
  uploadedCounter: number
  fileBaseUrl: string
}
export interface IFolder {
  id?: string
  isPlaceholder?: boolean
  hasError?: boolean
  errorText?: string
  isMultiselected: boolean
  files: Array<IMedia>
  isExpanded: boolean
}
export interface IIndexedItems {
  [key: string]: number
}
export interface ILastClicked {
  wasSelected: boolean
  index: number | undefined
}
export interface ISetFoldersAction {
  media: Array<IMedia>
}
export interface IAddFolderAction {
  file: IMedia
}
export interface ISetFileBaseUrl {
  fileBaseUrl: string
}
export interface IUploadStatus {
  shouldUpload: boolean
  reason?: string
}
export interface IExtendedFile extends File {
  folderId: string
  assetId?: string
  uploadStatus?: IUploadStatus
}
export interface ITags {
  catalogueTag: string
}
export interface IUploadRequestAction {
  files: FileList
  matchByFilename: boolean
  tags: ITags
}
export interface IAddUploadPlaceholders {
  files: Array<IExtendedFile>
}
export interface ITransformPlaceholder {
  fileId?: string
  success: boolean
  folderId: string
  type?: string
  title?: string
  error?: string
}
export interface IPendingCounter {
  number: number
}
export interface IToggleFolderSelection {
  folderId: string
}
export interface IToggleMultipleFolderSelection {
  folder: Partial<IFolder>
}
export interface ISelectAllFolders {
  allSelected: boolean
}
export interface IUpdateIndexFromPosition {
  newIndex: number
  folderId: string
}
export interface ISetExpandedFolder {
  id: string
}
export interface IDeleteFoldersRequest {
  folders: Array<Partial<IFolder>>
}
export interface IAssignFoldersRequest {
  folders: Array<IFolder>
}
export interface IEditFileRequest {
  id: string
  angle?: 90 | 180 | 270 | 0
  newFileName?: string
}
export interface IEditFile {
  file: Partial<IMedia>
}
export interface IRemoveFileFromFolder {
  fileId: string
  folderId: string
}
interface IReorderFiles {
  id: string
  folderId: string
}
interface ISetLeadImage {
  folderId: string
  leadImageId: string
}
export const fetchMedia: ActionCreatorWithoutPayload = createAction('fetchMedia')
export const uploadRequest: ActionCreatorWithPayload<IUploadRequestAction> = createAction('uploadRequest')
export const setFolders: ActionCreatorWithPayload<ISetFoldersAction> = createAction('setFolders')
export const addFolder: ActionCreatorWithPayload<IAddFolderAction> = createAction('addFolder')
export const setFileBaseUrl: ActionCreatorWithPayload<ISetFileBaseUrl> = createAction('setFileBaseUrl')
export const addUploadPlaceholders: ActionCreatorWithPayload<IAddUploadPlaceholders> = createAction('addUploadPlaceholders')
export const transformPlaceholder: ActionCreatorWithPayload<ITransformPlaceholder> = createAction('transformPlaceholder')
export const incrementInitialyUploadedCounterBy: ActionCreatorWithPayload<number> = createAction('incrementInitialyUploadedCounterBy')
export const incrementUploadedCounter: ActionCreatorWithoutPayload = createAction('incrementUploadedCounter')
export const resetUploadCounters: ActionCreatorWithoutPayload = createAction('resetUploadCounters')
export const toggleFolderSelection: ActionCreatorWithPayload<IToggleFolderSelection> = createAction('toggleFolderSelection')
export const toggleMultipleSelection: ActionCreatorWithPayload<IToggleMultipleFolderSelection> = createAction('toggleMultipleSelection')
export const selectAllFolders: ActionCreatorWithPayload<ISelectAllFolders> = createAction('selectAllFolders')
export const mergeFolders: ActionCreatorWithoutPayload = createAction('mergeFolders')
export const unmergeFolders: ActionCreatorWithoutPayload = createAction('unmergeFolders')
export const resetSelectedFolders: ActionCreatorWithoutPayload = createAction('resetSelectedFolders')
export const updateIndexFromPosition: ActionCreatorWithPayload<IUpdateIndexFromPosition> = createAction('updateIndexFromPosition')
export const setExpandedFolder: ActionCreatorWithPayload<ISetExpandedFolder> = createAction('setFolderExpanded')
export const deleteFoldersRequest: ActionCreatorWithPayload<IDeleteFoldersRequest> = createAction('deleteFolderRequest')
export const deleteFolders: ActionCreatorWithPayload<IDeleteFoldersRequest> = createAction('deleteFolders')
export const assigningFoldersByIndexNumberRequest: ActionCreatorWithPayload<IAssignFoldersRequest> = createAction('assigningFoldersByIndexNumberRequest')
export const editFileRequest: ActionCreatorWithPayload<IEditFileRequest> = createAction('editFileRequest')
export const editFile: ActionCreatorWithPayload<IEditFile> = createAction('editFileInFolder')
export const removeFileFromFolderRequest: ActionCreatorWithPayload<IRemoveFileFromFolder> = createAction('removeFileFromFolderRequest')
export const removeFileFromFolder: ActionCreatorWithPayload<IRemoveFileFromFolder> = createAction('removeFileFromFolder')
export const reorderFiles: ActionCreatorWithPayload<IReorderFiles> = createAction('reorderFiles')
export const setLeadImage: ActionCreatorWithPayload<ISetLeadImage> = createAction('setLeadImage')
export const checkGroupedItems: ActionCreatorWithoutPayload = createAction('checkGroupedItems')

export const initialState: IMediaState = {
  folders: [],
  indexedItems: {},
  initialyUploadedCounter: 0,
  lastClicked: {
    wasSelected: false,
    index: undefined
  },
  uploadedCounter: 0,
  fileBaseUrl: ''
}

const reducer = createReducer(initialState, {
  [setFolders.type]: (state, { payload: { media } }: PayloadAction<ISetFoldersAction>) => {
    state.folders = media.map((media) => getBlankFolder({
      id: StringUtils.getUUID(),
      files: [{
        ...media,
        position: 0,
        baseUrl: `${state.fileBaseUrl}/v2/files/${media.id}/image`,
        updatedDate: Date.now().toString()
      }]
    }))
  },
  [addFolder.type]: (state, { payload: { file } }: PayloadAction<IAddFolderAction>) => {
    state.folders.unshift(
      getBlankFolder({
        id: StringUtils.getUUID(),
        files: [{
          ...file,
          position: 0,
          baseUrl: `${state.fileBaseUrl}/v2/files/${file.id}/image`,
          updatedDate: Date.now().toString()
        }]
      })
    )
  },
  [setFileBaseUrl.type]: (state, { payload: { fileBaseUrl } }: PayloadAction<ISetFileBaseUrl>) => {
    state.fileBaseUrl = fileBaseUrl
  },
  [addUploadPlaceholders.type]: (state, { payload: { files } }: PayloadAction<IAddUploadPlaceholders>) => {
    state.folders = generateUploadPlaceholders(state.folders, files)
  },
  [transformPlaceholder.type]: (state, { payload }: PayloadAction<ITransformPlaceholder>) => {
    state.folders = state.folders.map(folder =>
      folder.id === payload.folderId
        ? resolvePlaceholderTransform(folder, payload, state.fileBaseUrl)
        : folder
    )
  },
  [incrementInitialyUploadedCounterBy.type]: (state, { payload }: PayloadAction<number>) => {
    state.initialyUploadedCounter = state.initialyUploadedCounter + payload
  },
  [incrementUploadedCounter.type]: (state) => {
    state.uploadedCounter++
  },
  [resetUploadCounters.type]: (state) => {
    state.initialyUploadedCounter = 0
    state.uploadedCounter = 0
  },
  [toggleFolderSelection.type]: (state, { payload: { folderId } }: PayloadAction<IToggleFolderSelection>) => {
    state.lastClicked.index = state.folders.findIndex((stateFolder) => stateFolder.id === folderId)
    if (state.indexedItems[folderId] !== undefined) {
      const currentClickedFolder = state.indexedItems[folderId]
      let offset = 0
      let stopIncrement = false
      delete state.indexedItems[folderId]
      state.indexedItems = Object.keys(state.indexedItems)
        .reduce((acc: IIndexedItems, cv) => {
          if (stopIncrement) {
            acc[cv] = state.indexedItems[cv]
            return acc
          }
          const currentLoopFolderIndex = state.indexedItems[cv]
          const isBaseValue = ((currentLoopFolderIndex - 1) === currentClickedFolder)
          const nextIndexIsBoundry = (currentLoopFolderIndex - (currentClickedFolder + offset)) > 1
          const loopFolderIndexSmallerThanClickedFolder = currentLoopFolderIndex < currentClickedFolder
          // if there's no big jump between deselected and current value
          if (isBaseValue) {
            offset++
            acc[cv] = currentClickedFolder
            return acc
          }
          if (!nextIndexIsBoundry) {
            loopFolderIndexSmallerThanClickedFolder ? acc[cv] = currentLoopFolderIndex : acc[cv] = currentClickedFolder + offset++
          } else {
            stopIncrement = !loopFolderIndexSmallerThanClickedFolder
            acc[cv] = currentLoopFolderIndex
          }
          return acc
        }, {})
      state.lastClicked.wasSelected = false
    } else {
      state.indexedItems[folderId] = Object.entries(state.indexedItems).length === 0 ? 0 : (Math.max(...Object.values(state.indexedItems)) + 1)
      state.lastClicked.wasSelected = true
    }
  },
  [toggleMultipleSelection.type]: (state, { payload: { folder } }: PayloadAction<IToggleMultipleFolderSelection>) => {
    if (Object.entries(state.indexedItems).length > 0) {
      const currentSelectedIndex = state.folders.findIndex((stateFolder) => stateFolder.id === folder.id)
      const isAscending = state.lastClicked.index < currentSelectedIndex
      const firstSlice = isAscending ? state.lastClicked.index : currentSelectedIndex
      const secondSlice = !isAscending ? state.lastClicked.index : currentSelectedIndex
      const selectableFolders = !isAscending
        ? state.folders.slice(firstSlice, (secondSlice + 1)).reverse()
        : state.folders.slice(firstSlice, (secondSlice + 1))
      if (!state.lastClicked.wasSelected) {
        // deletes all indexes between the two clicks
        selectableFolders.forEach(selectableFolder => delete state.indexedItems[selectableFolder.id])
        state.lastClicked.wasSelected = false
      } else {
        let indexedItemsHighestIndex = state.indexedItems[Object.keys(state.indexedItems).reduce((acc, cv) => state.indexedItems[acc] > state.indexedItems[cv] ? acc : cv)] + 1
        selectableFolders.forEach(selectableFolder => {
          if (state.indexedItems[selectableFolder.id] === undefined && !selectableFolder.isPlaceholder) state.indexedItems[selectableFolder.id] = indexedItemsHighestIndex++
        })
      }
    } else {
      state.indexedItems[folder.id] = 0
      state.lastClicked.wasSelected = true
    }
  },
  [updateIndexFromPosition.type]: (state, { payload: { newIndex, folderId } }: PayloadAction<IUpdateIndexFromPosition>) => {
    const originalIndex = state.indexedItems[folderId]
    if (newIndex === (originalIndex + 1)) return
    const adjustedIndex = (newIndex - 1)
    const newIsLower = adjustedIndex < originalIndex
    let offset = newIsLower ? 1 : 0
    state.indexedItems[folderId] = adjustedIndex
    const indexedItems = Object.keys(state.indexedItems)
      .reduce((acc: IIndexedItems, cv) => {
        if ((newIsLower && cv !== folderId && state.indexedItems[cv] >= adjustedIndex) || (!newIsLower && state.indexedItems[cv] > originalIndex)) {
          // if cv needs to increment
          acc[cv] = (adjustedIndex + offset)
          offset++
        } else {
          // if cv shouldn't change
          acc[cv] = state.indexedItems[cv]
        }
        return acc
      }, {})
    state.indexedItems = Object.entries(indexedItems)
      .sort((a, b) => a[1] - b[1])
      .reduce((acc: any, cv) => {
        acc[cv[0]] = cv[1]
        return acc
      }, {})
    state.lastClicked.index = adjustedIndex + offset - 1
  },
  [setExpandedFolder.type]: (state, { payload: { id } }: PayloadAction<ISetExpandedFolder>) => {
    state.folders.forEach(folder => {
      folder.isExpanded = folder.id === id
    })
  },
  [mergeFolders.type]: (state) => {
    const { images, documents } = state.folders
      .filter(folder => state.indexedItems[folder.id] !== undefined)
      .sort((a, b) => state.indexedItems[a.id] - state.indexedItems[b.id])
      .reduce((acc: Array<IMedia>, curr) => {
        acc.push(...curr.files)
        return acc
      }, [])
      .reduce((acc: { images: Array<IMedia>, documents: Array<IMedia> }, file) => {
        if (isFileAnImage(file)) {
          acc.images.push(file)
        } else {
          acc.documents.push(file)
        }
        return acc
      }, { images: [], documents: [] })
    const files = images.concat(documents).map((file, index) => ({
      ...file,
      position: index
    }))
    const firstIndexedFolderId = Object.keys(state.indexedItems)[0]
    const newFolder = getBlankFolder({
      id: firstIndexedFolderId,
      files
    })
    delete state.indexedItems[firstIndexedFolderId]
    state.folders = state.folders
      .filter(folder => !state.indexedItems[folder.id] && state.indexedItems[folder.id] !== 0)
      .map(folder => folder.id === firstIndexedFolderId
        ? newFolder
        : folder)
    state.indexedItems = {}
  },
  [selectAllFolders.type]: (state, { payload: { allSelected } }: PayloadAction<ISelectAllFolders>) => {
    state.indexedItems = {}
    const selectableFolders = state.folders.filter(folder => !folder.isPlaceholder)
    if (!allSelected) {
      selectableFolders.forEach((folder, index) => {
        state.indexedItems[folder.id] = index
      })
    }
  },
  [reorderFiles.type]: (state, { payload: { folderId, id } }: PayloadAction<IReorderFiles>) => {
    state.folders.forEach(folder => {
      if (folder.id === folderId) folder.files.sort((a, b) => a.id === id ? -1 : b.id === id ? 1 : 0)
    })
  },
  [setLeadImage.type]: (state, { payload: { folderId, leadImageId } }: PayloadAction<ISetLeadImage>) => {
    const files = state.folders.filter(folder => folder.id === folderId)[0].files
    const newLeadIndex = files.findIndex(file => file.id === leadImageId)
    files.unshift(files.splice(newLeadIndex, 1)[0])
    files.forEach((file, index) => {
      file.position = index
    })
  },
  [deleteFolders.type]: (state, { payload: { folders } }: PayloadAction<IDeleteFoldersRequest>) => {
    const idsToDelete = folders.map(folder => folder.id)
    state.folders = state.folders.filter(folder => !idsToDelete.includes(folder.id))
  },
  [editFile.type]: (state, { payload: { file } }: PayloadAction<IEditFile>) => {
    const folderIndex = state.folders
      .findIndex(folder => folder.files.findIndex(stateFile => stateFile.id === file.id) !== -1)
    const folder = state.folders[folderIndex]
    const fileIndex = folder.files.findIndex(stateFile => stateFile.id === file.id)
    const oldFile = state.folders[folderIndex].files[fileIndex]
    state.folders[folderIndex].files[fileIndex] = { ...oldFile, ...file }
  },
  [removeFileFromFolder.type]: (state, { payload: { fileId, folderId } }: PayloadAction<IRemoveFileFromFolder>) => {
    const oldFolderIndex = state.folders.findIndex(folder => folder.id === folderId)
    const oldFolder = state.folders[oldFolderIndex]
    const newFolder = getBlankFolder({
      files: [oldFolder.files.find(file => file.id === fileId)]
    })
    oldFolder.files = oldFolder.files.filter(file => file.id !== fileId)
    state.folders[oldFolderIndex] = oldFolder
    state.folders.splice(oldFolderIndex + 1, 0, newFolder)
    state.folders[(oldFolderIndex)].files.forEach((file, index) => {
      file.position = index
    })
  },
  [unmergeFolders.type]: (state) => {
    // Old school loop has to be used here as we're adding items to the array and forEach doesn't iterate over the added items
    for (let i = 0; i < state.folders.length; i++) {
      if (state.indexedItems[state.folders[i].id] !== undefined && state.folders[i].files.length > 1) {
        state.folders.splice(i, 1, ...state.folders[i].files.map(file => getBlankFolder({
          files: [file]
        })))
      }
    }
  },
  [resetSelectedFolders.type]: (state) => {
    state.indexedItems = {}
  }
})

export * from './selectors'
export default reducer
