import React from 'react'
import styled from 'styled-components'
import { Button, GridContainer, Modal, Paper, Typography } from 'bdx-af-ui/core'
import { Close } from 'bdx-af-ui/icons'

interface IFolderRemoveModalProps {
  isOpen: boolean
  onConfirm: () => void
  onClose: () => void
  numberOfFiles: number
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 4rem;
  position: relative;
  text-align: center;
`
const CloseButton = styled.button`
  position: absolute;
  right: 1rem;
  top: 1rem;
  border: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
`

export const DeleteConfirmationModal: React.FunctionComponent<IFolderRemoveModalProps> = ({ isOpen, onConfirm, onClose, numberOfFiles }) => {
  const handleConfirm = () => {
    onConfirm()
  }
  const handleClose = () => {
    onClose()
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Paper>
        <Container>
          <CloseButton onClick={handleClose}>
            <Close />
          </CloseButton>
          <Typography variant='h2' marginBottom='3rem'>Are you sure you want to delete {numberOfFiles} file{numberOfFiles !== 1 ? 's' : ''}?</Typography>
          <GridContainer>
            <Button variant='text' size='large' onClick={handleClose}>Cancel</Button>
            <Button variant='contained' size='large' onClick={handleConfirm} >Delete</Button>
          </GridContainer>
        </Container>
      </Paper>
    </Modal>
  )
}

export default DeleteConfirmationModal
