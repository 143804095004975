import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { IRootState } from '../../../store/rootReducer'
import { getFolders, getDocuments, getImages } from '../../../store/ducks/media'

interface ITotalItemsCounter {
  totalImages: number
  totalDocuments: number
  totalFolders: number
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  height: 100%;
`

export const TotalItemsCounter: React.FunctionComponent<ITotalItemsCounter> = ({ totalImages, totalDocuments, totalFolders }) => (
  <Container>
    Images ({totalImages}) :: Documents ({totalDocuments}) :: Items ({totalFolders})
  </Container>
)

const mapStateToProps = (state: IRootState) => ({
  totalImages: getImages(state).length,
  totalDocuments: getDocuments(state).length,
  totalFolders: getFolders(state).filter(folder => !folder.isPlaceholder).length
})

export default connect(mapStateToProps)(TotalItemsCounter)
