import Compressor from 'compressorjs'
import { UploadUtils } from 'bdx-af-ui/utils'

import { IExtendedFile } from '../store/ducks/media'
import { IMedia } from '../api/media'

export const checkFileAndCompress = (file: IExtendedFile, options: Compressor.Options) => (
  isFileSuitableForCompression(file)
    ? UploadUtils.compressImage(file, options).then(res => res).catch(() => file)
    : file
)

export const SUPPORTED_DOCUMENT_TYPES = [
  'text/csv',
  'text/plain',
  'text/rtf',
  'application/xml',
  'application/pdf',
  'application/rtf',
  'application/vnd.oasis.opendocument',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]
export const SUPPORTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/png'
]

export const SUPPORTED_FILE_TYPES = SUPPORTED_DOCUMENT_TYPES.concat(SUPPORTED_IMAGE_TYPES)
export const MAX_FILE_SIZE = 20048301
export const isFileSizeSuported = (file: File) => file.size < MAX_FILE_SIZE
export const isFileTypeSupported = (file: File) => new RegExp(SUPPORTED_FILE_TYPES.join('|')).test(file.type)
export const isFileSuitableForCompression = (file: File) => file.type === 'image/jpeg' || file.type === 'image/png'
export const isFileSupported = (file: File) => isFileSizeSuported(file) && isFileTypeSupported(file)
export const isFileAnImage = (file: File | IMedia) => new RegExp(SUPPORTED_IMAGE_TYPES.join('|')).test(file.type)
export const isFileAnDocument = (file: File | IMedia) => new RegExp(SUPPORTED_DOCUMENT_TYPES.join('|')).test(file.type)
export const getUnsupportedError = (file: File) => {
  if (!isFileTypeSupported(file)) {
    return `Upload failed. ${file.name} has unsupported type.`
  }
  if (!isFileSizeSuported(file)) {
    return `Upload failed. ${file.name} is too big. Max. 20 MB.`
  }
  return ''
}
