import { createAction, createReducer, ActionCreatorWithPayload, PayloadAction, ActionCreatorWithoutPayload } from '@reduxjs/toolkit'

export interface IAssetsState {
  assets: Array<IAsset>
}
export interface IAssetFile {
  id: string
  baseUrl: string
  type: string
  position: number
}
export interface IAsset {
  id: number
  title: string
  description?: any
  files: Array<IAssetFile>
  rowNumber?: number
  isSelected?: boolean
  uuid: string
  assetStatus: string
}
interface ISetAssets {
  assets: Array<IAsset>
}
interface IToggleAssetSelection {
  uuid: string
}
interface IEditAsset {
  asset: Partial<IAsset>
}
export interface IRemoveFileFromAsset {
  assetUuid: string
  fileId: string
}
export interface ISetLeadImageOnAsset {
  assetUuid: string
  fileId: string
}

export const fetchAssets: ActionCreatorWithoutPayload = createAction('fetchAssets')
export const clearAssets: ActionCreatorWithoutPayload = createAction('clearAssets')
export const setAssets: ActionCreatorWithPayload<ISetAssets> = createAction('setAssets')
export const setLeadImageOnAsset: ActionCreatorWithPayload<ISetLeadImageOnAsset> = createAction('setLeadImageOnAsset')
export const toggleAssetSelection: ActionCreatorWithPayload<IToggleAssetSelection> = createAction('toggleAssetSelection')
export const editAsset: ActionCreatorWithPayload<IEditAsset> = createAction('editAsset')
export const removeFileFromAssetRequest: ActionCreatorWithPayload<IRemoveFileFromAsset> = createAction('removeFileFromAssetRequest')
export const removeFileFromAsset: ActionCreatorWithPayload<IRemoveFileFromAsset> = createAction('removeFileFromAsset')

const initialState: IAssetsState = {
  assets: []
}

const reducer = createReducer(initialState, {
  [setAssets.type]: (state, { payload: { assets } }: PayloadAction<ISetAssets>) => {
    state.assets = assets.map((asset, index) => ({
      id: asset.id,
      title: asset.title,
      description: asset.description,
      files: asset.files,
      rowNumber: index + 1,
      isSelected: false,
      uuid: asset.uuid,
      assetStatus: asset.assetStatus
    }))
  },
  [toggleAssetSelection.type]: (state, { payload: { uuid } }: PayloadAction<IToggleAssetSelection>) => {
    state.assets.forEach(asset => {
      asset.isSelected = asset.uuid === uuid && !asset.isSelected
    })
  },
  [editAsset.type]: (state, { payload: { asset } }: PayloadAction<IEditAsset>) => {
    state.assets = state.assets.map(stateAsset =>
      stateAsset.uuid === asset.uuid
        ? ({
          ...stateAsset,
          ...asset
        })
        : stateAsset
    )
  }
})

export * from './selectors'
export default reducer
